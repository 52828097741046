import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { useForm } from "react-hook-form";

export function GuideModal({ open, handleClose, handleSetGuide }) {

  const methods = useForm({ defaultValues: { guideNumber: '' } });
  const { handleSubmit, register } = methods;

  const onSubmit = (data) => handleSetGuide({guideNumber: data.guideNumber});

  return (
    <>
      <form id="guideForm" onSubmit={handleSubmit(onSubmit)}>

        <Dialog open={open} onClose={handleClose}>

          <DialogTitle>Numero de Guia</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ingresa el Numero de Guia de esta Orden de Venta
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              required
              name="guideNumber"
              id="guideNumber"
              {...register('guideNumber')}
            ></TextField>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" form="guideForm" >
              Continuar
            </Button>
          </DialogActions>

        </Dialog>
      </form>
    </>
  )
}