import { useEffect, useReducer } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { ConfirmationDialog } from "../../../ui";
import { currencyFormat } from "../../../../utils/Commons";
import { StatusChip2 } from "../../../ui";
import { ACTIONS, QuotationActions } from "./QuotationActions";
import { useNavigate } from "react-router-dom";

//params: state (s) and action (a)
function reducer(s, a) {
  if (a.type === ACTIONS.RESET) return { ...s, action: "", id: 0 };
  return { ...s, action: a.type, id: a.payload.id };
}

export function QuotationsList({ quotations }) {
  const [state, dispatch] = useReducer(reducer, { action: "", id: 0 });
  const navigate = useNavigate();

  function handlAprove() {
    console.log("aproving", state.id);
  }

  useEffect(() => {
    if (state.action === ACTIONS.EDIT) navigate("/cotizaciones/" + state.id);
    if (state.action === ACTIONS.PREVIEW)
      window.open("/cotizaciones/" + state.id + "/pdf", "_blank");
  }, [state]);
  function abortAction() {
    dispatch({ type: ACTIONS.RESET });
  }

  return (
    <>
      <List>
        {quotations.map((q) => {
          const secondary = `${new Date(q.createdAt).toLocaleDateString(
            "es-ar"
          )} - ${q.name} - ${q.total}`;
          return (
            <ListItem
              key={q.id}
              secondaryAction={
                <QuotationActions quotation={q} dispatch={dispatch} />
              }
            >
              <ListItemText
                primary={
                  <StatusChip2 status={q.status} text={`PC0000${q.id}`} />
                }
                secondary={secondary}
              ></ListItemText>
            </ListItem>
          );
        })}
      </List>

      <ConfirmationDialog
        title="Aprobar Cotización"
        message="Estás a punto de aprobar la cotización. ¿Deseas continuar?"
        open={state.action === ACTIONS.APROVE}
        callBack={handlAprove}
        handleClose={abortAction}
      />
      <ConfirmationDialog
        title="Cancelar Cotización"
        message="Estás a punto de cancelar la cotización. Al cancelarla se dará por perdida la oferta. ¿Deseas continuar?"
        open={state.action === ACTIONS.CANCEL}
        handleClose={abortAction}
      />
    </>
  );
}
