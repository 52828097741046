import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { SignInPage } from "./pages";
import { Delivery } from "./features/sales";
import { Provider } from "react-redux";
import { store } from "./store";
import { Notification } from "./features/notifications";
import { AppLayout } from "./features/ui";
import { getUser } from "./utils/Commons";

import { Accounts, AppRoutes, UserGroups } from "./features/auth";
import {
  MyOrders,
  Orders,
  OrderEditor,
  ProductMeta,
  Quotations,
  QuotationEditor,
} from "./features/sales";

import { ThemeProvider } from "@mui/material/styles";
import { appTheme } from "./theme";
import { QuotationPDF } from "./features/sales/Quotations/components/QuotationPDF";

function App() {
  let user = getUser();
  if (user)
    return (
      user && (
        <>
          <Provider store={store}>
            <ThemeProvider theme={appTheme}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <AppLayout>
                      <OrderEditor />
                    </AppLayout>
                  }
                />
                <Route
                  path="/mis-ventas"
                  element={
                    <AppLayout>
                      <MyOrders />
                    </AppLayout>
                  }
                />
                <Route
                  path="/ventas"
                  element={
                    <AppLayout>
                      <Orders readOnly={true} />
                    </AppLayout>
                  }
                />

                <Route
                  path="/administracion"
                  element={
                    <AppLayout>
                      <Orders readOnly={false} />
                    </AppLayout>
                  }
                />
                <Route path="/remito/:id" element={<Delivery />} />

                <Route
                  path="/cotizaciones"
                  element={
                    <AppLayout>
                      <Quotations />
                    </AppLayout>
                  }
                />
                <Route
                  path="/nueva-cotizacion"
                  element={
                    <AppLayout>
                      <QuotationEditor />
                    </AppLayout>
                  }
                />
                <Route
                  path="/cotizaciones/:id"
                  element={
                    <AppLayout>
                      <QuotationEditor />
                    </AppLayout>
                  }
                />
                <Route
                  path="/cotizaciones/:id/pdf"
                  element={<QuotationPDF />}
                />
                <Route
                  path="/product-meta"
                  element={
                    <AppLayout>
                      <ProductMeta />
                    </AppLayout>
                  }
                />

                <Route
                  path="/accounts"
                  element={
                    <AppLayout>
                      <Accounts />
                    </AppLayout>
                  }
                />
                <Route
                  path="/app-routes"
                  element={
                    <AppLayout>
                      <AppRoutes />
                    </AppLayout>
                  }
                />
                <Route
                  path="/user-groups"
                  element={
                    <AppLayout>
                      <UserGroups />
                    </AppLayout>
                  }
                />
              </Routes>
            </ThemeProvider>
            <Notification />
          </Provider>
        </>
      )
    );
  else
    return (
      <Provider store={store}>
        <SignInPage />
        <Notification />
      </Provider>
    );
}

export default App;
