import { useEffect, useState } from "react";
import { useGetOrderQuery, useAddCOTMutation } from "../../../api/restApiSlice";
import { useLazyGetClienteQuery } from "../../../api/contabiliumApiSlice";
import { useNotification } from "../../../notifications";
import { useCommons } from "../../../ui/hooks/useCommons";
import { padWithLeadingZeroes } from "../../../../utils/Commons";

const ERROR_ORDER = {
  message: "No se puede obtener la Orden de Venta",
  type: "error",
};

const emptyEntrega = {
  calle: "",
  nro: "",
  pisoDepto: "",
  cp: "",
  localidad: "",
  formated: "",
  provincia: "",
  fecha: "",
  hora: "",
};

export function useDelivery(orderId) {
  const [cliente, setCliente] = useState();
  const [entrega, setEntrega] = useState(emptyEntrega);
  const { displayNotification } = useNotification();
  const { citiesSuccess, stateSuccess, selectState, currentState } =
    useCommons();
  const [getCliente, { data: clienteData, isSuccess: clienteSuccess }] =
    useLazyGetClienteQuery();
  const [addCotMutation, { isError: addCotError, isSuccess: addCotSuccess }] =
    useAddCOTMutation();
  const {
    data: order,
    isError: orderError,
    isLoading: orderLoading,
    isSuccess: orderSuccess,
  } = useGetOrderQuery(orderId);

  useEffect(() => {
    orderError && displayNotification(ERROR_ORDER);
  }, [orderError]);

  useEffect(() => {
    order && getCliente(order.idCliente);
  }, [order]);

  useEffect(() => {
    if (stateSuccess && orderSuccess && clienteSuccess) {
      if (order.provinciaEntrega) selectState(order.provinciaEntrega);
      else selectState(clienteData.IdProvincia);
    }
  }, [stateSuccess, orderSuccess, clienteSuccess]);

  useEffect(() => {
    setCliente(clienteData);
  }, [clienteSuccess]);

  useEffect(() => {
    if (!clienteSuccess || !orderSuccess) return;

    var info = {};
    const {
      direccionEntrega,
      nroEntrega,
      pisoDeptoEntrega,
      localidadEntrega,
      cpEntrega,
    } = { ...order };

    if (direccionEntrega) {
      info = {
        ...emptyEntrega,
        calle: direccionEntrega,
        nro: nroEntrega,
        pisoDepto: pisoDeptoEntrega,
        cp: cpEntrega,
        localidad: localidadEntrega,
        formated: `${direccionEntrega} ${nroEntrega ? nroEntrega : ""} ${
          pisoDeptoEntrega ? pisoDeptoEntrega : ""
        }`,
        provincia: currentState(),
      };
    } else {
      const { Domicilio, Ciudad, Cp } = { ...cliente };
      info = {
        ...emptyEntrega,
        calle: Domicilio,
        cp: Cp,
        localidad: Ciudad,
        formated: Domicilio,
        provincia: currentState(),
      };
    }
    info.fecha = order.deliveries[0]?.deliveryDate;
    info.hora = order.deliveries[0]?.deliveryTime;

    setEntrega(info);
  }, [clienteSuccess, orderSuccess, stateSuccess, citiesSuccess]);

  function addCot(formData) {
    const { CondicionIva, TipoDoc, NroDoc, RazonSocial } = { ...cliente };
    formData.cliente = { CondicionIva, TipoDoc, NroDoc, RazonSocial };
    console.log(formData);
    addCotMutation({ id: orderId, data: formData });
  }
  const { nombre, nroDoc, condicionVenta } = { ...order };
  const { CondicionIva, Telefono, Email } = { ...cliente };

  return {
    order,
    nombre,
    nroDoc,
    nroRemito: orderSuccess
      ? padWithLeadingZeroes(order?.deliveries[0]?.number, 9)
      : null,
    fechaRemito: orderSuccess
      ? new Date(order?.deliveries[0]?.createdAt).toLocaleDateString("es-ar")
      : null,
    costoEnvio: orderSuccess ? order?.deliveries[0]?.shippingCost : null,
    entrega,
    condicionVenta,
    CondicionIva,
    Telefono,
    Email,
    isSuccess: clienteSuccess && orderSuccess && stateSuccess && citiesSuccess,
    items: (orderSuccess && order?.order_items) ? order.order_items : [],
    addCot,
  };
}
