export const config = {
  
  
  restServerUrl: "https://rest.kaliteknos.com",
  apiUrl: "https://rest.kaliteknos.com/v1/accounts",
  salesUrl: "https://rest.kaliteknos.com/v1/sales",

/* 
  restServerUrl: 'http://localhost:3000',
  apiUrl: 'http://localhost:3000/v1/accounts',
  salesUrl: 'http://localhost:3000/v1/sales', 
   */

  contabiliumUrl: "https://rest.contabilium.com/api",

  condicionVtaBiocook: 314927,
  maxDiscountPerc: 7,
  orderStatus: {
    pending: 0,
    aproved: 1,
    processing: 3,
    finished: 90,
    canceled: 91,
  },
};

export const ACTIONS = {
  HIDE: "hide",
  SHOW: "show",
};

export const DIALOGS = {
  DETAIL: "detail",
  IMAGES: "images",
  NEW_COMMENT: "new-comment",
  APROVE_ORDER: "aprove-order",
  MARK_PAYED: "mark-payed",
  CANCEL: "cancel",
  FINISH_ORDER: "finish-order",
  CREATE_DELIVERY: "create-delivery",
  DELETE_DELIVERY: "delete-delivery",
  ADD_GUIDE: "add-guide",
  CREATE_COT: "create-cot",
};
