import { useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useComments } from "../hooks/useComments";

const LeftItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
}));

const RightItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
}));

export function CommentsDialog({ order, handleCloseComments, openComments }) {
  const { sortComments, submitComment, isSuccess } = useComments();

  useEffect(() => {
    if (isSuccess) handleCloseComments();
  }, [isSuccess]);

  return (
    <Dialog
      onClose={handleCloseComments}
      open={openComments}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>Comentarios</DialogTitle>

      <Stack spacing={1} padding={1}>
        {sortComments(order.comments).map((comment) =>
          comment.ltr ? (
            <LeftItem key={comment.id}>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                {comment.account.fullname[0]}
              </Avatar>
              <div>
                <Typography variant="body1" paddingLeft={1}>
                  {comment.message}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 11 }}
                  paddingLeft={1}
                >
                  {new Date(comment.createdAt).toLocaleDateString("es-ar")}
                </Typography>
              </div>
            </LeftItem>
          ) : (
            <RightItem key={comment.id}>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                {comment.account.fullname[0]}
              </Avatar>
              <div>
                <Typography variant="body1" paddingRight={1}>
                  {comment.message}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 11 }}
                  paddingRight={1}
                >
                  {new Date(comment.createdAt).toLocaleDateString("es-ar")}
                </Typography>
              </div>
            </RightItem>
          )
        )}
      </Stack>

      <Divider />

      <form id="commentForm" onSubmit={submitComment}>
        <input type="hidden" name="id" value={order.id} />
        <Grid container spacing={1} mt={2} p={1}>
          <Grid item xs={10}>
            <TextField name="message" variant={"outlined"} fullWidth />
          </Grid>
          <Grid item xs={1}>
            <IconButton variant="outlined" type="submit">
              <SendIcon color="primary" fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}
