import { useEffect, useState } from "react"
import { accountsService } from "../../../../services"

export function useQuotations() {
  const [quotations, setQuotations] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    accountsService.getQuotations()
      .then(
        res => {
          if (res.success) {setQuotations(res.data)}
          //else raiseErr
        },
        err => {console.error(err)}
      )
      .finally( () => setLoading(false))
  }, [])
  return { loading, quotations }
}