import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';

import { useForm } from "react-hook-form";

import { ProductCard } from './ProductCard';
import { contabiliumService } from '../../../../services'
import { IconTitle, SelectListasDePrecios } from '../../../ui';
import { useNotification } from '../../../notifications'

export function ProductSearch({ addProduct, enableNoStock }) {

  const methods = useForm({ defaultValues: { qry: '' } });
  const { handleSubmit, register } = methods;

  const { displayNotification } = useNotification();
  const [productos, setProductos] = useState([]);
  const [productosLoaded, setProductosLoaded] = useState(false);
  const [listaDePrecios, setListaDePrecios] = useState(7869) // TODO: OJO ACA CON EL HARDCODE
  const [interestRate, setInterestRate] = useState(0)

  const startQuery = async (qry) => {
    let res = await contabiliumService.searchProducts(20, qry)
    if (res.success) {
      setProductos(res.data.Items);
      setProductosLoaded(true);
    } else {
      setProductosLoaded(false);
      displayNotification({
        message: "Oh oh... falló la comunicación con Contabilium",
        type: "error"
      })
    }
  }
  const handleInterestRateChange = (value) => {
    setInterestRate(value)
  }
  const onSubmit = (data) => {
    startQuery(data.qry)
  }

  return (
    <Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IconTitle icon={AddShoppingCart}>Productos</IconTitle>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectListasDePrecios itemSelected={setListaDePrecios} selected={listaDePrecios} interestRateChanged={handleInterestRateChange} />
            </Grid>
          </Grid>

          <FormControl sx={{ marginTop: 2, marginBottom: 1 }} variant="standard" fullWidth>
            <InputLabel htmlFor="buscar-productos">Buscar Productos</InputLabel>
            <Input id="buscar-productos"
              name="qry"
              {...register('qry')}
              endAdornment={<InputAdornment position='end'> <IconButton type='submit'><SearchIcon /></IconButton></InputAdornment>}
            />
          </FormControl>
        </form>
      </Box>


      <Box sx={{ minHeight: 400, maxHeight: 850, overflowY: "scroll" }}>
        <Grid container spacing={1}>
          {productosLoaded && productos.length > 0 ?
            (
              productos.map(producto => {
                return (
                  <Grid key={producto.Id} item xs={12} lg={6}>
                    <ProductCard producto={producto} addProductClicked={addProduct} interestRate={interestRate} enableNoStock={enableNoStock}/>
                  </Grid>)
              })
            ) : (
              <Grid item xs={12} sx={{ minHeight: 400 }} bgcolor="transparent">
                <Typography
                  variant='h5'
                  sx={{ marginTop: 10 }}>
                  No se encontraron productos que coincidan con su busqueda...
                </Typography>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Box>
  );
}

