import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'

export function IconTitle(props) {
  const Icon = props.icon
  return (
    <Grid container direction="row" alignItems="center" spacing="2" sx={{ paddingBottom: 1 }}>
      {(Icon) ? <Grid item ><Icon/></Grid> : ''}
      <Grid item>
        <Typography sx={{ marginLeft: 0.75 }} variant="h6" color="#b11629" gutterBottom>
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
}

IconTitle.propTypes = {
  children: PropTypes.node,
};
