import { configureStore } from "@reduxjs/toolkit";
import { NotificationReducer } from "./features/notifications/state/notificationSlice";
import { restApiSlice } from "./features/api/restApiSlice";
import { accountsApiSlice } from "./features/api/accountsApiSlice";
import { contabiliumApiSlice } from "./features/api/contabiliumApiSlice";

export const store = configureStore({
  reducer: {
    notification: NotificationReducer,
    [restApiSlice.reducerPath]: restApiSlice.reducer,
    [accountsApiSlice.reducerPath]: accountsApiSlice.reducer,
    [contabiliumApiSlice.reducerPath]: contabiliumApiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(restApiSlice.middleware)
      .concat(accountsApiSlice.middleware)
      .concat(contabiliumApiSlice.middleware)
});

export const RootState = store.getState();