import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../services/config";
import { getToken } from "../../utils/Commons";

const ORDER_ENDPOINT = "/order";
const ORDERS_ENDPOINT = "/orders";
const ORDERS_TAG = "Orders";
const PRODUCT_META_TAG = "product-meta";

const authHeader = () => {
  var token = getToken();
  if (token) return { Authorization: "Bearer " + token };
  return {};
};

const getOrderQuery = {
  query: (orderId) => ({
    url: `${ORDER_ENDPOINT}/${orderId}`,
    headers: authHeader(),
  }),
};

const getOrdersQuery = {
  query: ({ from, to }) => ({
    url: `${ORDERS_ENDPOINT}?from=${from}&to=${to}`,
    headers: authHeader(),
  }),
  providesTags: [ORDERS_TAG],
};

const getMyOrderseQuery = {
  query: () => ({
    url: "/my-orders",
    headers: authHeader(),
  }),
  providesTags: [ORDERS_TAG],
};

const addOrderMutation = {
  query: (order) => ({
    url: "${ORDERS_ENDPOINT}",
    method: "POST",
    headers: authHeader(),
    body: order,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const addCommentMutation = {
  query: ({ id, data }) => ({
    url: `${ORDER_ENDPOINT}/${id}/comment`,
    method: "POST",
    headers: authHeader(),
    body: data,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const replyCommentMutation = {
  query: ({ id, data }) => ({
    url: `${ORDER_ENDPOINT}/${id}/reply-comment`,
    method: "POST",
    headers: authHeader(),
    body: data,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const attachFileMutation = {
  query: ({ id, data }) => ({
    url: `/attachments/${id}`,
    method: "POST",
    headers: authHeader(),
    body: data,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const setOrderStateMutation = {
  query: ({ id, data }) => ({
    url: `${ORDER_ENDPOINT}/${id}/estado`,
    method: "PUT",
    headers: authHeader(),
    body: { estado: data },
  }),
  invalidatesTags: [ORDERS_TAG],
};

const setOrderPayedMutation = {
  query: (id) => ({
    url: `${ORDER_ENDPOINT}/${id}/payed`,
    method: "PUT",
    headers: authHeader(),
  }),
  invalidatesTags: [ORDERS_TAG],
};

const createDeliveryMutation = {
  query: ({ id, data }) => ({
    url: `/delivery/${id}`,
    method: "POST",
    headers: authHeader(),
    body: data,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const deleteDeliveryMutation = {
  query: (id) => ({
    url: `/delivery/${id}`,
    method: "DELETE",
    headers: authHeader(),
  }),
  invalidatesTags: [ORDERS_TAG],
};

const addGuideMutation = {
  query: ({ id, data }) => ({
    url: `${ORDER_ENDPOINT}/${id}/guide-number`,
    method: "PUT",
    headers: authHeader(),
    body: data,
  }),
  invalidatesTags: [ORDERS_TAG],
};

const addCOTMutation = {
  query: ({ id, data }) => {
    console.log("dataaaa", data)
    return {
    url: `${ORDER_ENDPOINT}/${id}/cot`,
    method: "POST",
    headers: authHeader(),
    body: data,
  }},
  //invalidatesTags: [ORDERS_TAG],
};

const getProductsMeta = {
  query: () => ({
    url: "/product-meta",
    headers: authHeader(),
  }),
  providesTags: [PRODUCT_META_TAG],
};

const getProductMeta = {
  query: (id) => ({
    url: `/product-meta/${id}`,
    headers: authHeader(),
  }),
  providesTags: [PRODUCT_META_TAG],
};

const updateProductMeta = {
  query: ({ id, body }) => {
    return {
      url: `/product-meta/${id}`,
      method: "PUT",
      headers: authHeader(),
      body,
    };
  },
  invalidatesTags: [PRODUCT_META_TAG],
};

const addProductMeta = {
  query: ({ body }) => {
    return {
      url: "/product-meta",
      method: "POST",
      headers: authHeader(),
      body,
    };
  },
  invalidatesTags: [PRODUCT_META_TAG],
};

export const restApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: config.salesUrl }),
  tagTypes: [ORDERS_TAG, PRODUCT_META_TAG],
  endpoints: (builder) => ({
    getOrders: builder.query(getOrdersQuery),
    getMyOrders: builder.query(getMyOrderseQuery),
    getOrder: builder.query(getOrderQuery),
    getProductMeta: builder.query(getProductMeta),
    getProductsMeta: builder.query(getProductsMeta),
    addOrder: builder.mutation(addOrderMutation),
    addComment: builder.mutation(addCommentMutation),
    replyComment: builder.mutation(replyCommentMutation),
    attachFile: builder.mutation(attachFileMutation),
    setOrderState: builder.mutation(setOrderStateMutation),
    setOrderPayed: builder.mutation(setOrderPayedMutation),
    createDelivery: builder.mutation(createDeliveryMutation),
    deleteDelivery: builder.mutation(deleteDeliveryMutation),
    addGuide: builder.mutation(addGuideMutation),
    addCOT: builder.mutation(addCOTMutation),
    updateProductMeta: builder.mutation(updateProductMeta),
    addProductMeta: builder.mutation(addProductMeta)
  }),
});

export const {
  useGetOrdersQuery,
  useGetMyOrdersQuery,
  useGetOrderQuery,
  useLazyGetProductMetaQuery,
  useGetProductsMetaQuery,
  useAddOrderMutation,
  useAddCommentMutation,
  useAddProductMetaMutation, 
  useReplyCommentMutation,
  useAttachFileMutation,
  useSetOrderStateMutation,
  useSetOrderPayedMutation,
  useCreateDeliveryMutation,
  useDeleteDeliveryMutation,
  useAddGuideMutation,
  useAddCOTMutation,
  useUpdateProductMetaMutation,
} = restApiSlice;
