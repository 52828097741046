import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ShoppingCart from "@mui/icons-material/ShoppingCart";

import { Title, IconTitle } from "../../../ui";
import { currencyFormat } from "../../../../utils/Commons";
import { CustomerData } from "../../Customer";

import { useOrder } from "../hooks/useOrder";
import DetailsPanel from "./DetailsPanel";
import { ShippingForm } from "./ShippingForm";
import { useNotification } from "../../../notifications";

export function OrderDetail({ open, handleClose, orderId, readOnly }) {
  const { displayNotification } = useNotification();
  const onSaved = (notification) => {
    displayNotification(notification);
    if (notification.type === "success") handleClose();
  };

  const {
    order,
    saveOrder,
    details,
    updateDetails,
    shippingInfo,
    updateShipping,
    mustRequired,
    customer,
  } = useOrder({ id: orderId, onSaved });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "95%",
    overflowY: "scroll",
  };

  const onSubmit = () => {
    saveOrder();
  };
  var total = 0;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Title>Orden {order && order.ordenContabiliumNro}</Title>

        <Box sx={{ marginBottom: 2 }}>
          <CustomerData customer={customer} />
        </Box>
        <DetailsPanel
          details={details}
          updateDetails={updateDetails}
          readOnly={readOnly || order.comprobanteContabilium !== null}
        />

        <IconTitle icon={ShoppingCart}>Productos</IconTitle>
        {order && order.order_items?.length > 0 && (
          <Table size="small" sx={{ mb: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Cant. - Descripcion</TableCell>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="right">Precio Unit.</TableCell>
                <TableCell align="right">Precio Bonif.</TableCell>
                <TableCell align="right" size="small">
                  Bonif.
                </TableCell>
                <TableCell align="right">Sub Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {order.order_items.map((item) => {
                total +=
                  item.precioUnitario *
                  1.21 *
                  item.cantidad *
                  (1 - item.bonificacion / 100);
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.cantidad} - {item.descripcion}</TableCell>
                    <TableCell align="left">{(item.SKU)}</TableCell>
                    <TableCell align="right">
                      {currencyFormat(item.precioUnitario * 1.21)}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormat(
                        item.precioUnitario *
                          1.21 *
                          (1 - item.bonificacion / 100)
                      )}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {item.bonificacion}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormat(
                        item.precioUnitario *
                          1.21 *
                          item.cantidad *
                          (1 - item.bonificacion / 100)
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="h6">Total</Typography>
                </TableCell>
                <TableCell>{currencyFormat(total)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <ShippingForm
          shippingInfo={shippingInfo}
          updateShipping={updateShipping}
          mustRequired={mustRequired}
          readOnly={
            readOnly || (order.deliveries && order.deliveries.length > 0)
          }
        />

        {!readOnly && (
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={1}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="medium"
                  color="error"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={onSubmit}
                  fullWidth
                  size="medium"
                  color="success"
                  variant="contained"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
