import { config } from "../services/config";

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  let user = getUser();
  return user.token || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (user) => {
  sessionStorage.setItem('user', JSON.stringify(user));
}

// set the Contabilium token and from the session storage
export const setContabiliumToken = (token) => {
  sessionStorage.setItem('kaliteknos_api_token', JSON.stringify(token));
} 

export const getContabiliumToken = () => {
  let token = sessionStorage.getItem('kaliteknos_api_token');
  if (token) return JSON.parse(token)
  else return null
}

export const currencyFormat = (num) => {
  return '$ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getStatusLabel(status) {
  let {processing, pending, aproved, canceled, finished} = config.orderStatus
  
  if (status === processing) return 'en curso'
  if (status === pending) return 'pendiente'
  if (status === aproved) return 'aprobada'
  if (status === canceled) return 'cancelada'
  if (status === finished) return 'finalizada'

}

export function getStatusColor(order) {
  let {processing, pending, aproved, canceled, finished} = config.orderStatus
  const { estado} = order

  if (estado === canceled) return 'error'
  if (estado === finished) return 'secondary'
  if (estado === processing) return 'primary'
  if (estado === pending) return 'warning'
  if (estado === aproved) return 'info'
}

export function padWithLeadingZeroes(num, totalLength) {
  return String(num).padStart(totalLength, "0")
}