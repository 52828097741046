import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'

export function CustomerData({ customer }) {
  return (
    <Grid container spacing={2} sx={{ margin: 0 }}>
      <Grid item sm={5}>
        <Typography variant='subtitle2'>Nombre/Razón Social</Typography>
        <Typography>{customer ? customer?.RazonSocial : '.'}</Typography>
      </Grid>
      <Grid item sm={7}>
        <Typography variant='subtitle2'>CUIT/CUIL/DNI</Typography>
        <Typography>{customer ? customer.TipoDoc + ' - ' + customer.NroDoc : '.'}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant='subtitle2'>Direccion</Typography>
        <Typography>{customer ? customer.Domicilio + (customer.PisoDepto ? ' - ' + customer.PisoDepto : '') : '.'}</Typography>
      </Grid>
      <Grid item xs={9} md={5}>
        <Typography variant='subtitle2'>Provincia y Localidad</Typography>
        <Typography>{customer ? customer.Provincia + (customer.Ciudad !== 'SIN IDENTIFICAR' ? ', ' + customer.Ciudad : '') : '.'}</Typography>
      </Grid>
      <Grid item xs={3} md={2}>
        <Typography variant='subtitle2'>Cod. Postal</Typography>
        <Typography>{customer ? customer.Cp : '.'}</Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography variant='subtitle2'>Email</Typography>
        <Typography>{customer ? customer.Email : '.'}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant='subtitle2'>Telefono (opcional)</Typography>
        <Typography>{customer ? customer.Telefono : '.'}</Typography>
      </Grid>
    </Grid>

  )
}