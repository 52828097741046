import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
//import dayjs from 'dayjs';
import "dayjs/locale/es";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useDelivery } from "../../Delivery";
import { SelectProvincias } from "../../../ui";

export function COTDialog({ open, handleClose, id }) {
  const { entrega, isSuccess, addCot } = useDelivery(id);

  function onSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let calle = data.get("calle");
    let nro = data.get("nro");
    let pisoDepto = data.get("pisoDepto");
    let cp = data.get("cp");
    let localidad = data.get("localidad");
    let almacen = data.get("almacen");
    let puerta = data.get("puerta");
    let fecha = data.get("fecha");
    let cuitTransporte = data.get("cuitTransporte");
    let patente = data.get("patente");
    let provincia = data.get("provincia");
    addCot({
      calle,
      nro,
      pisoDepto,
      cp,
      localidad,
      almacen,
      puerta,
      fecha,
      cuitTransporte,
      patente,
      provincia
    });
    //handleSetGuide({ guideNumber: data.guideNumber });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      component="form"
      onSubmit={onSubmit}
      maxWidth="lg"
    >
      <DialogTitle>Generar COT</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ p: 0.5, mb: 1.5 }}>
          <Typography variant="subtitle">
            Por favor, confirmá y completa la informacion necesaria para
            procesar el COT en Arba.
          </Typography>
        </DialogContentText>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              {isSuccess ? (
                <TextField
                  fullWidth
                  required
                  name="calle"
                  label="Calle"
                  defaultValue={entrega && entrega.calle}
                />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              {isSuccess ? (
                <TextField
                  fullWidth
                  required
                  name="nro"
                  label="Número"
                  defaultValue={entrega && entrega.nro}
                />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              {isSuccess ? (
                <TextField
                  fullWidth
                  name="pisoDepto"
                  label="Piso"
                  defaultValue={entrega && entrega.pisoDepto}
                />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              {isSuccess ? (
                <TextField
                  fullWidth
                  required
                  name="cp"
                  label="Cod. Pos."
                  defaultValue={entrega && entrega.cp}
                />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {isSuccess ? (
                <TextField
                  fullWidth
                  required
                  name="localidad"
                  label="Localidad"
                  defaultValue={entrega && entrega.localidad}
                />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {isSuccess ? (
                <SelectProvincias provincia={entrega?.provincia?.ID} />
              ) : (
                <Skeleton variant="rectangular" height={56} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                autoFocus
                fullWidth
                required
                name="almacen"
                label="Almacen"
                defaultValue="001"
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                required
                name="puerta"
                label="Puerta"
                defaultValue="001"
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                name="fecha"
                label="Fecha COT"
                /* 
                You can set required in a DatePicker by adding the 
                slotProps prop for textfield (you can read more about 
                it in the mui component API section for datepicker) 
                */
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                name="cuitTransporte"
                label="CUIT del Transporte"
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                name="patente"
                label="Patente"
              ></TextField>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
