import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { AdminOrderActions } from './AdminOrderActions';
import { AttachmentsChip } from './AttachmentsChip';
import { OrderInfoIcons } from './OrderInfoIcons';
import { StatusChip } from './StatusChip';

import { currencyFormat } from '../../../../utils/Commons';

export const OrderRow = (
  {
    order, readOnly, showCancel, aproveOrder, onOrderAction, billOrder,
    receiptOrder, showDeliver, showDeliveryDelete, showGuide, showFinish,
    markPayed, handleDetailOpen, showImages, navDelivery, addComment, showCOT
  }
) =>
  <TableRow key={order.id}>

    {/**Estado */}
    <TableCell align='center' size='small'>
      <StatusChip status={order.estado} />
    </TableCell>

    {/** Nro de Orden en Contabilum */}
    <TableCell>
      <Link color="primary" href="#"
        onClick={
          (e) => {
            e.preventDefault()
            handleDetailOpen(order.id)
          }
        }>
        {order.ordenContabiliumNro}
      </Link>
    </TableCell>

    {/** Cliente */}
    <TableCell>
      {order.nombre}
    </TableCell>

    {/** Vendedor */}
    <TableCell>{order.account.fullname}</TableCell>

    {/** Fecha de Emision */}
    <TableCell>{new Date(order.fechaEmision).toLocaleDateString('es-ar')}</TableCell>

    {/** Total */}
    <TableCell align="right">{currencyFormat(Number(order.total))}</TableCell>

    {/** Nro de Guia */}
    <TableCell>{order && order.numeroGuia}</TableCell>

    {/** Adjuntos */}
    <TableCell>
      <AttachmentsChip order={order} showImages={showImages} showDelivery={orderId => navDelivery(orderId)} />
    </TableCell>

    {/** Info */}
    <TableCell>
      <OrderInfoIcons order={order} />
    </TableCell>

    {/** Actions */}
    {!readOnly &&
      <TableCell>
        <AdminOrderActions
          order={order}
          showCancel={showCancel}
          aproveOrder={aproveOrder}
          onAprove={onOrderAction}
          billOrder={billOrder}
          receiptOrder={receiptOrder}
          showDeliver={showDeliver}
          showDeliveryDelete={showDeliveryDelete}
          showGuide={showGuide}
          showFinish={showFinish}
          markPayed={markPayed}
          addComment={addComment}
          createCOT={showCOT}
        />
      </TableCell>}

  </TableRow>