import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel';
import { useForm } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';

export function OrdersTextSearch({ query, onQueryChange }) {

  const methods = useForm({ defaultValues: { query } });
  const { handleSubmit, register } = methods;

  const onSubmit = (data) => {
    onQueryChange(data.query)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl variant="standard" fullWidth sx={{ mt: 6 }}>
        <InputLabel htmlFor="buscar-ordenes">Buscar Ordenes</InputLabel>
        <Input id="buscar-ordenes"
          name="query"
          {...register('query')}
          endAdornment={<InputAdornment position='end'> <IconButton type='submit'><SearchIcon /></IconButton></InputAdornment>}
        />
      </FormControl>
    </form>
  )
}