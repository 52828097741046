import { useEffect, useState } from "react";
import {
  useGetProvinciasQuery,
  useLazyGetCiudadesQuery,
} from "../../api/contabiliumApiSlice";
import { useNotification } from "../../notifications";

const ERROR_CIUDADES = {
  message: "No se pueden cargar los datos de las ciudades",
  type: "error",
};
const ERROR_PROVINCIAS = {
  message: "No se pueden cargar los datos de las provincias",
  type: "error",
};

export function useCommons() {
  const { displayNotification } = useNotification();
  const [
    getCiudades,
    { data: citiesData, isSuccess: citiesSuccess, isError: citiesError },
  ] = useLazyGetCiudadesQuery();
  const [provincia, setProvincia] = useState();
  const [ciudades, setCiudaes] = useState([]);
  const {
    data: provincias,
    isError,
    isLoading,
    isSuccess: stateSuccess,
  } = useGetProvinciasQuery();

  useEffect(() => {
    isError && displayNotification(ERROR_PROVINCIAS);
  }, [isError]);

  useEffect(() => {
    citiesSuccess && setCiudaes(citiesData);
  }, [citiesSuccess]);

  useEffect(() => {
    citiesError && displayNotification(ERROR_CIUDADES);
  }, [citiesError]);

  function selectState(id) {
    setProvincia(id);
    getCiudades(id);
  }
  function currentState() {
    return stateSuccess ? provincias.filter((p) => p.ID === provincia)[0] : null;
  }
  return { ciudades, provincias, selectState, currentState, stateSuccess, citiesSuccess };
}
