import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../services/config";
import { getContabiliumToken } from "../../utils/Commons";

const CLIENTES_ENDPOINT = "/clientes";
const CLIENTES_TAG = "Clientes";
const CIUDADES_ENDPOINT = "/common/ciudades";
const CIUDADES_TAG = "Ciudades";
const PROVINCIAS_ENDPOINT = "/common/provincias?idPais=10";
const PROVINCIAS_TAG = "Provincias";

//TODO: Repetida de restApiSLice - abstraer
const authHeader = () => {
  var token = getContabiliumToken();
  //TODO: getContab...ken() devuelva el access_token cuando se limpie el resto de contabilium.service
  if (token) return { Authorization: "Bearer " + token.access_token };
  return {};
};

const getCiudades = {
  query: (id) => ({
    url: `${CIUDADES_ENDPOINT}?idProvincia=${id}`,
    headers: authHeader(),
  }),
  providesTags: [CIUDADES_TAG],
};

const getCliente = {
  query: (id) => ({
    url: `${CLIENTES_ENDPOINT}?id=${id}`,
    headers: authHeader(),
  }),
  providesTags: [CLIENTES_TAG],
};

const getProvincias = {
  query: () => ({
    url: PROVINCIAS_ENDPOINT,
    headers: authHeader(),
  }),
  providesTags: [CIUDADES_TAG],
};

export const contabiliumApiSlice = createApi({
  reducerPath: "contabilium",
  baseQuery: fetchBaseQuery({ baseUrl: config.contabiliumUrl }),
  tagTypes: [PROVINCIAS_TAG],
  endpoints: (builder) => ({
    getCiudades: builder.query(getCiudades),
    getCliente: builder.query(getCliente),
    getProvincias: builder.query(getProvincias),
  }),
});

export const {
  useGetProvinciasQuery,
  useLazyGetCiudadesQuery,
  useLazyGetClienteQuery,
} = contabiliumApiSlice;
