import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function SelectCanales({selected, changeCanal, disabled=false}) {

  return (

    <FormControl fullWidth required disabled={disabled}>
      <InputLabel id="tipos-select">Canal</InputLabel>
      <Select
        labelId="tipos-select"
        id="tipos-select"
        value={selected ? selected : ''}
        label="Canal"
        required
        onChange={e => changeCanal(e.target.value)}
      >
        <MenuItem value="">
          <em>selecciona un Canal</em>
        </MenuItem>
        <MenuItem key="COR" value="COR">Corporativo</MenuItem>
        <MenuItem key="B2C" value="B2C">B2C</MenuItem>
        <MenuItem key="TCO" value="TCO">Todo Cocinas</MenuItem>
        <MenuItem key="HCO" value="HCO">Hogar/Cocina</MenuItem>
      </Select>
    </FormControl>

  );
}