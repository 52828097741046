import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";

import { useGetUserGroupsQuery } from "../../api/accountsApiSlice";
import { Avatar, CardHeader, ListItemAvatar } from "@mui/material";

const groupListItem = (group) => {
  const { accounts, appRoutes, id, name } = { ...group };
  return (
    <>
      <ListItem
        key={id}
        secondaryAction={
          <IconButton edge="end" aria-label="editar">
            <EditIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar>{name[0].toUpperCase()}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            <Stack>
              <Typography variant="body">
                Usuarios: {accounts.map((a) => a.fullname).join(", ")}
              </Typography>
              <Typography variant="body2">
                Rutas: {appRoutes.map((r) => "/" + r.route).join(" ")}
              </Typography>
            </Stack>
          }
        />
      </ListItem>
      <Divider variant="inset"/>
    </>
  );
};

export function UserGroups() {
  const { data, isError, isLoading, isSuccess } = useGetUserGroupsQuery();
  return (
    <Card>
      <CardHeader title="Grupos" />
      <CardContent>
        {isSuccess && <List>{data.map(groupListItem)}</List>}
      </CardContent>
    </Card>
  );
}
