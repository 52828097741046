import { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from "react-hook-form";

import { modalStyle } from './modalStyle'
import { FormInputText, FormInputDropdown, Title } from "../../../ui";
import { useNotification } from '../../../notifications';

import { contabiliumService } from '../../../../services';
const tipos = [
  { ID: "CUIT", Nombre: "CUIT" },
  { ID: "CUIL", Nombre: "CUIL" },
  { ID: "DNI", Nombre: "Doc. Nac. de Identidad" },
]

const defaultValues = {
  RazonSocial: "",
  TipoDoc: "",
  NroDoc: "",
  Domicilio: "",
  IdPais: "10",
  IdProvincia: "1",
  CiudadNombre: "",
  Cp: "",
  CondicionIva: "",
  Email: "",
  Telefono: ""
};

export function NewCustomer(props) {

  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue } = methods;
  const [paises, setPaises] = useState([])
  const [provincias, setProvincias] = useState([])
  const [ciudades, setCiudades] = useState([])
  const [loading, setLoading] = useState(false);

  let open = props.open
  let handleClose = props.handleClose
  let customerSelected = props.customerSelected

  const { displayNotification } = useNotification();

  useEffect(() => {
    contabiliumService.getPaises()
      .then(
        p => setPaises(p.data),
        err => {
          setPaises([])
          console.log(err)
        }
      )
    contabiliumService.getProvincias()
      .then(
        p => setProvincias(p.data),
        err => {
          setProvincias([])
          console.log(err)
        }
      )
  }, [])

  const onAfipSubmit = (data) => {

    //no uso reset para no borrar el nro de doc ingresado por el usuario
    setValue('TipoDoc', "");
    setValue('Cp', "")
    setValue('RazonSocial', "");
    setValue('CondicionIva', "");
    setValue('Domicilio', "");
    setValue('IdPais', 10)
    setValue('CategoriaImpositiva', "")
    setValue('Email', "")
    setValue('Telefono', "")

    setLoading(true)

    contabiliumService.searchAfip(data.NroDoc)
      .then(responseData => {
        if (responseData.success) {
          setValue('TipoDoc', responseData.data.TipoDoc);
          setValue('NroDoc', responseData.data.NroDoc);
          setValue('Cp', responseData.data.CodigoPostal)
          setValue('RazonSocial', responseData.data.RazonSocial);
          //setValue('CondicionIva', responseData.data.CondicionIva);
          setValue('Domicilio', responseData.data.Domicilio);
          setValue('IdPais', 10)
          setValue('CategoriaImpositiva', responseData.data.CategoriaImpositiva)
          setValue('CiudadNombre', responseData.data.CiudadNombre)
          setValue('IdProvincia', responseData.data.ProvinciaId)
          if (responseData.data.ProvinciaId) contabiliumService.getCiudades(responseData.data.ProvinciaId)
            .then(
              p => {
                setCiudades(p.data)
                setValue('IdCiudad', responseData.data.CiudadId)
              },
              err => {
                setCiudades([])
                console.log(err)
              }
            )

        } else {
          displayNotification({ message: responseData.message.Message, type: "warning" })
        }
        setLoading(false)
      }, error => {
        displayNotification({ message: "No se pudo consultar AFIP", type: "error" })
        setLoading(false)
      })

  };

  const onSubmit = (data) => {
    setLoading(true)
    contabiliumService.createCliente(data)
      .then(
        r => {
          if (r.success) {
            displayNotification({ message: "El cliente se guardo correctamente.", type: "success" })
            contabiliumService.getCliente(r.data)
              .then(c => {
                if (c.success) {
                  setLoading(false)
                  customerSelected(c.data)
                  handleClose()
                } else {
                  setLoading(false)
                  displayNotification({ message: "Ocurrio un error al obtener los datos desde Contabilium.", type: "error" })
                }
              })
          } else {
            setLoading(false)
            displayNotification({ message: "Los datos del cliente no pudieron guardarse! El cliente ya existía?", type: "error" })
          }
        }
      )
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title>Nuevo Cliente</Title>
            <Grid container spacing={1}>

              <Grid item xs={3}>
                <FormInputDropdown name="TipoDoc" control={control} label="Tipo Doc." options={tipos} disabled={loading} />
              </Grid>

              <Grid item xs={6}>
                <FormInputText name="NroDoc" control={control} label="Nro. Doc." />
              </Grid>

              <Grid item xs={3}>
                <Button onClick={handleSubmit(onAfipSubmit)} variant={"outlined"} fullWidth endIcon={(loading) ? <CircularProgress /> : <SearchIcon size="middle" />}>
                  AFIP
                </Button>

              </Grid>

              <Grid item xs={10}>
                <FormInputText name="RazonSocial" control={control} label="Razón Social" required disabled={loading} />
              </Grid>

              <Grid item xs={2}>
                <FormInputText name="CategoriaImpositiva" control={control} label="Cat. Impositiva" required disabled={loading} />
              </Grid>



              <Grid item xs={12}>
                <FormInputText name="Domicilio" control={control} label="Domicilio" required disabled={loading} />
              </Grid>

              <Grid item xs={6}>
                <FormInputDropdown name="IdPais" control={control} label="Pais" options={paises} required />
              </Grid>

              <Grid item xs={6}>
                <FormInputDropdown name="IdProvincia" control={control} label="Provincia" options={provincias} required disabled={loading} />
              </Grid>

              {/*               <Grid item xs={10}>
                <FormInputDropdown name="IdCiudad" control={control} label="Ciudad" options={ciudades} required disabled={loading} />
              </Grid> */}

              <Grid item xs={10}>
                <FormInputText name="Ciudad" control={control} label="Ciudad" required disabled={loading} />
              </Grid>

              <Grid item xs={2}>
                <FormInputText name="Cp" control={control} label="Codigo Postal" required disabled={loading} />
              </Grid>



              <Grid item xs={6}>
                <FormInputText name="Email" control={control} label="Email" required disabled={loading} />
              </Grid>

              <Grid item xs={6}>
                <FormInputText name="Telefono" control={control} label="Teléfono" required disabled={loading} />
              </Grid>
            </Grid>


            {/* Botones */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button onClick={() => reset()} variant="outlined" fullWidth>Limpiar</Button>
              </Grid>
              <Grid item xs={4}>
                <Button type="submit" variant="contained" fullWidth>Guardar</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  )
}