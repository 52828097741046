import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const UploadImages = ({order, selectFile}) =>
  <label htmlFor={order.id}>
    <input
      id={order.id}
      name="btn-upload"
      hidden
      type="file"
      multiple
      accept="image/png, image/jpeg"
      onChange={selectFile} />
    <Tooltip title="Adjuntar archivos a esta orden">
      <IconButton component="span" color="warning" >
        <UploadFileIcon />
      </IconButton>
    </Tooltip>
  </label>
