import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";
import { ProductMetaEditor } from "./ProductMetaEditor";
import { ContabiliumProductsSearch } from "./ContabiliumProductsAutocomplete";

import { useAddProductMetaMutation } from "../../../api/restApiSlice";
import { useProductMeta } from "../hooks/useProductMeta";

export function ProductMeta() {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState();
  const { data: products } = useProductMeta();
  const [
    addProductMeta,
    { isLoading: metaLoading, isSuccess: metaSuccess, isError: metaError },
  ] = useAddProductMetaMutation();

  function onClose() {
    setOpenModal(false);
    setSelected();
  }

  function onClick(id) {
    setSelected(id);
    setOpenModal(true);
  }

  function listItem(itm) {
    const { id, sku, vendor, description } = { ...itm };
    const primary = `${sku ? sku : "no SKU"} - (${id})`;
    const secondary = `${vendor} - ${description}`;
    return (
      <ListItem
        key={id}
        secondaryAction={
          <IconButton
            edge="end"
            onClick={() => onClick(id)}
          >
            <EditIcon />
          </IconButton>
        }
      >
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  }

  function contabiliumProductSelected(product) {
    const { Id, Nombre, Codigo, Foto } = { ...product };
    const candidate = { id: Id, name: Nombre, sku: Codigo, imageUrl: Foto };
    console.log(candidate);
    addProductMeta(candidate);
  }
  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5" key="product-meta">
              Productos - Metadato
            </Typography>
            <Typography variant="body" key="help-text">
              Esta lista contiene la información extendida de los los productos
              que se importaron desde Contabilium. Podés agregar otros productos
              usando el buscador.
            </Typography>
            <ContabiliumProductsSearch onSelect={contabiliumProductSelected} />
            {products && <List>{products.map(listItem)}</List>}
          </Stack>
        </CardContent>
      </Card>
      <ProductMetaEditor open={openModal} onClose={onClose} id={selected} />
    </>
  );
}
