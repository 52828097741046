const { createSlice, createAction } = require("@reduxjs/toolkit");

const notificationInitialState = {
  open: false,
  type: "info",
  message: "",
  timeout: 4000,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    addNotification: (state, action) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    clearNotification: (state) => ({ ...state, open: false }),
  },
});

export const NotificationActions = {
  addNotification: createAction("notification/addNotification"),
  clearNotification: createAction("notification/clearNotification"),
};

export const NotificationReducer = NotificationSlice.reducer;

