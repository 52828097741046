import { useReducer, useState, useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { OrderDetail } from '../../Order';
import { NewCommentDialog } from "../../comments";
import { Title, StyledPaper, ConfirmationDialog } from '../../../ui';
import { useNotification } from '../../../notifications';
import { ImagesModal } from '../../../ui/components/ImagesModal';

import {
  useGetMyOrdersQuery,
  useAddCommentMutation,
  useAttachFileMutation,
  useSetOrderStateMutation,
} from '../../../api/restApiSlice';

import { OrdersTableHead } from './OrdersTableHead';
import { OrdersTableRow } from "./OrdersTableRow";

import { config, DIALOGS, ACTIONS } from "../../../../services/config";
import { contabiliumService } from "../../../../services";

const initialState = {
  dialog: '',
  selectedId: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW:
      const { dialog, selectedId } = { ...action.payload }
      return { ...state, dialog, selectedId }
    case ACTIONS.HIDE:
      return initialState
    default:
      return state
  }
}

export function MyOrders() {

  const [state, dispatch] = useReducer(reducer, initialState)
  const navDelivery = (orderId) => window.open(`/remito/${orderId}`, '_blank');

  const {
    data: orders,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMyOrdersQuery();

  const [addComment,
    {
      isLoading: isSavingComment,
      isSuccess: isCommnetSavedOk,
      isError: isCommentError
    }
  ] = useAddCommentMutation();

  const [attachFile,
    {
      isLoading: isAttaching,
      isSuccess: isAttachOk,
      isError: isAttachError
    }
  ] = useAttachFileMutation();

  const [setOrderState,
    {
      isLoading: isStateSaving,
      isError: isStateError,
      isSuccess: isStateSuccess
    }
  ] = useSetOrderStateMutation();


  useEffect(() => {
    isAttachOk && displayNotification({ message: "La imagen se adjuntó con exito", type: "success" })
    isStateSuccess && displayNotification({ message: "La orden fue cancelada exitósamente", type: "success" })
    isCommnetSavedOk && displayNotification({ message: "El comentario se agrego exitósamente", type: "success" })

    dispatch({ type: ACTIONS.HIDE })
  }, [isAttachOk, isStateSuccess, isCommnetSavedOk])

  useEffect(() => {
    isError && displayNotification({ message: "Ocurrio un error cargando la lista de ordenes.", type: "error" })
    isCommentError && displayNotification({ message: "No se pudo agregar el comentario.", type: "error" })
    isAttachError && displayNotification({ message: "No se pudo adjuntar la imagen.", type: "error" })
    isStateError && displayNotification({ message: "No se pudo cancelar la orden.", type: "error" })
  }, [isError, isCommentError, isAttachError, isStateError])

  const { displayNotification } = useNotification();
  const [orderImages, setOrderImages] = useState([])

  function cancelOrder() {
    try {
      setOrderState({ id: state.selectedId, data: config.orderStatus.canceled })
        .unwrap()
        .then(_ => contabiliumService.cancelOrder(state.selectedId))

    } catch (error) {
      //LUCAS: DESDE ACA!!!
    }
  }

  function selectFile(event) {
    if (!event.target.files) {
      return;
    }
    const id = event.target.id
    const files = Array.from(event.target.files);
    var data = new FormData()
    files.forEach(file => {
      data.append('images', file)
    })
    attachFile({ id, data })
  }

  function showImages(images) {
    setOrderImages(JSON.parse(images))
    dispatch({ type: ACTIONS.SHOW, payload: { dialog: DIALOGS.IMAGES } })
  }

  function showNewComment(orderId) {
    dispatch({ type: ACTIONS.SHOW, payload: { dialog: DIALOGS.NEW_COMMENT, selectedId: orderId } })
  }

  function showCancel(orderId) {
    dispatch({ type: ACTIONS.SHOW, payload: { dialog: DIALOGS.CANCEL, selectedId: orderId } })
  }

  return (
    <>
      <StyledPaper sx={{ minHeight: 550 }}>
        <Title>Ordenes de Venta</Title>

        {isLoading && <h3>Cargando</h3>}
        {isSavingComment && <h3>Guardando comentario</h3>}
        {isAttaching && <h3>Adjuntando Imagen</h3>}
        {isStateSaving && <h3>Cancelando la Orden</h3>}

        <Table size="small">
          <OrdersTableHead />
          <TableBody>
            {isSuccess &&
              orders.map(order =>
                <OrdersTableRow
                  key={order.id}
                  order={order}
                  handleDetailOpen={id => dispatch({ type: ACTIONS.SHOW, payload: { dialog: DIALOGS.DETAIL, selectedId: id } })}
                  navDelivery={navDelivery}
                  showImages={showImages}
                  selectFile={selectFile}
                  showNewComment={showNewComment}
                  showCancel={showCancel}
                />
              )
            }
          </TableBody>
        </Table>
      </StyledPaper>

      <ConfirmationDialog
        title="Cancelar Orden de Venta"
        message="Estás a punto de cancelar esta Orden. ¿Deséas continuar?"
        open={state.dialog === DIALOGS.CANCEL}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={cancelOrder}
      />

      <ImagesModal
        open={state.dialog === DIALOGS.IMAGES}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        imagesArray={orderImages}
      />

      <NewCommentDialog
        open={state.dialog === DIALOGS.NEW_COMMENT}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        createComment={(data) => addComment({ id: state.selectedId, data })}
      />

      <OrderDetail
        open={state.dialog === DIALOGS.DETAIL}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        orderId={state.selectedId}
        readOnly={false}
      />
    </>
  )
}