const React = require("react");
const { useSelector } = require("react-redux");
const { useNotification } = require("../hooks/useNotification");
const { Snackbar, Alert } = require("@mui/material");

export const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const { clearNotification } = useNotification();

  const handleClose = (_, reason) =>
    reason !== "clickaway" && clearNotification();

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={notification.type}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
