import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {
  useLazyGetProductMetaQuery,
  useUpdateProductMetaMutation,
} from "../../../api/restApiSlice";
import { useNotification } from "../../../notifications";

export function ProductMetaEditor({ open, onClose, id }) {
  const [trigger, result] = useLazyGetProductMetaQuery();
  const [updateProductMeta, { isError, isSuccess }] =
    useUpdateProductMetaMutation();
  const [productMeta, setProductMeta] = useState();
  const { displayNotification } = useNotification();

  useEffect(() => {
    isError &&
      displayNotification({
        message: "Ocurrio un error al actualizar los datos!",
        type: "error",
      });
    if (isSuccess) {
      displayNotification({
        message: "Los datos se actualizaron correctamente",
        type: "success",
      });
      onClose();
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (id) trigger(id);
    else setProductMeta();
  }, [id]);

  useEffect(() => {
    if (result.status === "fulfilled") {
      const { data } = result;
      setProductMeta(data);
    }
  }, [result]);

  function onSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const sku = data.get("sku");
    const description = data.get("description");
    const arba = data.get("arba");
    const imageUrl = data.get("imageUrl");
    const vendor = data.get("vendor");
    const body = { sku, description, arba, imageUrl, vendor };
    updateProductMeta({ id, body });
  }
  const showReady = open && productMeta !== undefined;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showReady}
      onClose={onClose}
      component="form"
      onSubmit={onSubmit}
    >
      <DialogTitle>Datos del Producto</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 0.5 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              name="vendor"
              defaultValue={showReady && productMeta.vendor}
              label="Marca"
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              name="sku"
              defaultValue={productMeta && productMeta.sku}
              label="SKU"
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              name="arba"
              defaultValue={productMeta && productMeta.arba}
              label="ARBA"
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="imageUrl"
              defaultValue={productMeta && productMeta.imageUrl}
              label="url de la imagen"
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              defaultValue={productMeta && productMeta.description}
              label="Descripcion"
              multiline
              fullWidth
              variant="filled"
              rows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
