import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/es'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Typography } from '@mui/material';

export function FromToDate({fromTo, onFromToChange}) {

  const updateFrom = v => {
    let d = dayjs(v).format().split('T')[0]
    if (d === 'Invalid Date') return
    let state = {
      ...fromTo,
      from: d
    }
    onFromToChange(state)
  }

  const updateTo = v => {
    let d = dayjs(v).format().split('T')[0]
    if (d === 'Invalid Date') return
    let state = {
      ...fromTo,
      to: d
    }
    onFromToChange(state)
  }
  return (
    <>
      <Typography variant='subtitle1' sx={{ mb: 2 }}>Periodo</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <Grid container spacing={1}>
          <Grid item>
            <DatePicker name="desde" label="Desde" defaultValue={dayjs(fromTo.from)} onChange={updateFrom} disableFuture/>
          </Grid>
          <Grid item>
            <DatePicker name="hasta" label="Hasta" defaultValue={dayjs(fromTo.to)} onChange={updateTo} disableFuture/>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
    
  )
}