import { useEffect } from "react";
import { useReplyCommentMutation } from "../../../api/restApiSlice";
import { useNotification } from "../../../notifications";

export const useComments = () => {
  const [replyComment, { isError, isSuccess }] = useReplyCommentMutation();
  const { displayNotification } = useNotification();

  useEffect(() => {
    isSuccess &&
      displayNotification({
        message: "La respuesta se envio éxitosamente",
        type: "success",
      });

    isError &&
      displayNotification({
        message: "Oh oh! Algo fallo en el Servidor de la aplicación.",
        type: "error",
      });
  }, [isSuccess, isError]);

  function sortComments(comments) {
    if(comments.length===0) return []
    var lastFullname = comments[0].account.fullname;
    var ltr = true;
    var sorted = [];
    for (var x = 0; x < comments.length; x++) {
      if (comments[x].account.fullname !== lastFullname) {
        ltr = !ltr;
        lastFullname = comments[x].account.fullname;
      }
      sorted.push({ ...comments[x], ltr });
    }
    return sorted;
  }

  function submitComment(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const id = data.get("id");
    const message = data.get("message");
    replyComment({ id, data: { message } });
  }

  return { sortComments, submitComment, isSuccess };
};
