import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';

export default function CustomerSearchButtons(props) {

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Button fullWidth size='medium' color="error" variant="outlined"
            onClick={props.handleClose}>Cerrar</Button>
        </Grid>
        <Grid item sm={6}>
          <Button 
          fullWidth 
          size='medium' 
          color="success"
          onClick={props.handleNewCustomer} 
          variant="contained">Nuevo Cliente</Button>
        </Grid>
      </Grid>
    </Box>
  )
}
