import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from '../../assets/logo-med.png';

export function DeliveryHeader(props) {

  let {number, date} = {...props}
  return (
    <Grid container sx={{ margin: 2 }}>
      <Grid item xs={4}>
        <Typography variant='h5'>KALITEKNOS SA</Typography>
        <Typography variant='body2'>Av Alvarez Thomas 636 6to C</Typography>
        <Typography variant='body2'>Chacarita, Ciudad de Buenos Aires</Typography>
        <Typography variant='body2'>Tel.: 11-22817903</Typography>
        <Typography variant='body2'>CUIT: 30608812659</Typography>
        <Typography variant='body2'>Ingresos brutos: 30608812659</Typography>
        <Typography variant='body2'>Responsable Inscripto</Typography>
        <Typography variant='body2'>Inicio de actividades: 19/10/1993</Typography>
      </Grid>
      <Grid item xs={4} >
      <img src={logo} alt="logo" style={{ width: "55%", height: "auto" }} />
      </Grid>
      <Grid item xs={4} >
        <Typography variant="h6">Remito</Typography>
        <Typography>Original</Typography>
        <Typography sx={{ marginTop: 1 }} variant="h5">Nº: 001-{number}</Typography>
        <Typography>Fecha: {date}</Typography>
        
      </Grid>
    </Grid>
  );
}