import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';

export function ActionButtons({saveOrder, canSave}) {

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4}>
          <Button fullWidth size='medium' color="error" variant="outlined">Limpiar</Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth size='medium' color="success" variant="contained" disabled={!canSave}
            onClick={saveOrder}>Procesar</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

