import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'

export function Title(props) {
  return (
    <Grid container direction="row" alignItems="center" spacing="2" sx={{ paddingBottom: 1 }}>
      <Grid item>
        <Typography sx={{ marginLeft: 0.75 }} variant="h6" color="primary" gutterBottom>
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
