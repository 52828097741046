import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';

import { useForm } from "react-hook-form";

export function DeliveryModal({ open, handleClose, createDelivery }) {

  const methods = useForm({
    defaultValues: {
      deliveryDate: '',
      deliveryTime: '',
      shippingCost: ''
    }
  });
  const { handleSubmit, register } = methods;

  const onSubmit = (data) => {
    createDelivery(data);
  }

  return (
    <>
      <form id="deliveryForm" onSubmit={handleSubmit(onSubmit)}>

        <Dialog open={open} onClose={handleClose}>

          <DialogTitle>Nuevo Remito</DialogTitle>
          <DialogContent>
            <DialogContentText id="delivery-dialog">
              Estas por generar el Remito de esta orden. Podés indicar adicionalmente los siguientes datos:
            </DialogContentText>
            <Grid container spacing={1} sx={{mb:1, mt:1}}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Fecha de Entrega"
                  name="deliveryDate"
                  id="deliveryDate"
                  {...register('deliveryDate')}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Hora de Enterga"
                  name="deliveryTime"
                  id="deliveryTime"
                  {...register('deliveryTime')}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Costo del Envío"
                  name="shippingCost"
                  id="shippingCost"
                  {...register('shippingCost')}
                ></TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" form="deliveryForm" >
              Continuar
            </Button>
          </DialogActions>

        </Dialog>
      </form>
    </>
  )
}