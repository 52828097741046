import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

import { CommentsDialog } from "../../comments";
import { useNotification} from '../../../notifications'
import { contabiliumService } from "../../../../services";

export function AttachmentsChip({ order, showDelivery, showImages }) {
  const { displayNotification } = useNotification();
  const [openComments, setOpenComments] = useState(false);

  const getComprobantePDF = (e, idComprobante) => {
    e.preventDefault();
    contabiliumService.getComprobantePDF(idComprobante).then(
      (f) => console.log(f),
      (err) => {
        displayNotification({
          message: "No se puede obtener la factura desde Contabilium",
          type: "error",
        });
        console.log(err);
      }
    );
  };

  function handleChipClick(e) {
    e.preventDefault();
    showImages(order.attachments);
  }

  const handleDeliveryClick = (e) => {
    e.preventDefault();
    showDelivery(order.id);
  };

  function handleCloseComments() {
    setOpenComments(false);
  }

  const linkStyle = { textDecoration: "none", fontSize: "1.5em" };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          {order.attachments !== null && (
            <Tooltip title="Ver Adjuntos">
              <span>
                <Link style={linkStyle} onClick={handleChipClick}>
                  📎
                </Link>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3}>
          {order.deliveries.length > 0 && (
            <Tooltip title="Ver Remito">
              <span>
                <Link style={linkStyle} onClick={handleDeliveryClick}>
                  🚚
                </Link>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3}>
          {order.comprobanteContabilium !== null && (
            <Tooltip title="Ver Factura">
              <span>
                <Link
                  style={linkStyle}
                  onClick={(e) =>
                    getComprobantePDF(e, order.comprobanteContabilium)
                  }
                >
                  💲
                </Link>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3}>
          {order.comments.length > 0 && (
            <Tooltip title="Comentarios">
              <span>
                <Link style={linkStyle} onClick={(e) => setOpenComments(true)}>
                  💬
                </Link>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <CommentsDialog
        openComments={openComments}
        order={order}
        handleCloseComments={handleCloseComments}
      />
    </>
  );
}
