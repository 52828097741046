import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';

import { useForm } from "react-hook-form";

export function NewCommentDialog({ open, handleClose, createComment }) {

  const methods = useForm({
    defaultValues: {
      message: '',
    }
  });
  const { handleSubmit, register } = methods;

  const onSubmit = (data) => {
    createComment(data);
  }

  return (
    <>
      <form id="commentForm" onSubmit={handleSubmit(onSubmit)}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Agregar un comentario:</DialogTitle>
          <DialogContent>
            <DialogContentText id="delivery-dialog">
              Escribe una comentario para adjuntar a la Orden. <br />
              Se notificará a Administración sobre el nuevo comentario.
            </DialogContentText>
            <Box sx={{padding:2}}>
              <TextField
                autoFocus
                fullWidth
                multiline
                rows={4}
                label="Mensaje"
                name="message"
                id="message"
                {...register('message')}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" form="commentForm" >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  )
}