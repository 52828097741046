import Tooltip from '@mui/material/Tooltip';
import { config } from '../../../../services/config'
import { Typography } from '@mui/material';

export function OrderInfoIcons({ order }) {

  return (
    <>
      {
      (order.fechaEntrega) && 
      <Tooltip title={"Se entrega: " + order.fechaEntrega}><Typography variant='h6'>🕓</Typography></Tooltip>
      }
      {
      (order.estado === 0) && 
      <Tooltip title="Esta Orden necesita aprobacion"><Typography variant='h6'>🔥</Typography></Tooltip>
      }
      {
      (order.condicionVenta === config.condicionVtaBiocook) && 
      <Tooltip title="Esta Orden se factura en BioCook"><Typography variant='h6'>💳</Typography></Tooltip>
      }
      {
      (order.pagado !== 0) && 
      <Tooltip title="Pago registrado"><Typography variant='h6'>🏁</Typography></Tooltip>
      }
      
    </>
  )
}