import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export const OrdersRowHeader = readOnly => {
  return (
    <TableHead>
      <TableRow>
        <TableCell size='small' align='center'>Est</TableCell>
        <TableCell size='small'>Nro</TableCell>
        <TableCell>Cliente</TableCell>
        <TableCell>Vendedor</TableCell>
        <TableCell size='small'>Fecha</TableCell>
        <TableCell size='small' align="right">Total</TableCell>
        <TableCell size='small' align="center"># Guía</TableCell>
        <TableCell size='small' align="center">Documentos</TableCell>
        <TableCell size='small' align="center">Alertas</TableCell>
        {!readOnly && <TableCell size='small' align="center"></TableCell>}

      </TableRow>
    </TableHead>
  )
}