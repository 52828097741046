import Box from '@mui/material/Box'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import { Title } from '../../../ui';
import CustomerSearchButtons from './CustomerSearchButtons'
import { modalStyle } from './modalStyle'

export function CustomerSearchResults({
  open,
  clientes,
  handleClose,
  handleNewCustomer,
  customerSelected
}) {

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Title>Resultados de la busqueda</Title>
        <List>
          {
            clientes.length > 0 && clientes.map(cliente => {
              return (
                <ListItem disablePadding key={cliente.Id} >
                  <ListItemButton component="a" onClick={() => { customerSelected(cliente) }}>
                    <ListItemText primary={cliente.RazonSocial + " - " + cliente.TipoDoc + ": " + cliente.NroDoc} />
                  </ListItemButton>
                </ListItem>
              )
            })
          }
        </List>
        <CustomerSearchButtons handleClose={handleClose} handleNewCustomer={handleNewCustomer} />
      </Box>
    </Modal>
  )
}
