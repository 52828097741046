import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { contabiliumService } from '../../../services';

export function SelectListasDePrecios({selected, itemSelected, interestRateChanged}) {

  const [listas, setListas] = useState([]);

  useEffect(() => {
    contabiliumService.getListasDePrecios()
      .then(
        p => {
          if (p.success) setListas(p.data)
          else {
            setListas([])
            console.log(p.message)
          }
        },
        err => {
          setListas([])
          console.log(err)
        }
      )
  }, [])

  const getInterestRate = (e) => {
    let i = listas.filter(lista => lista.ID === e.target.value)
    interestRateChanged(i[0].Porcentaje)
    itemSelected(e.target.value)
  }
  return (

    <FormControl fullWidth>
      <InputLabel id="listas-select">Lista De Precios</InputLabel>
      <Select
        labelId="listas-select"
        id="listas-select"
        value={listas.length > 0 && selected ? selected : ''}
        label="Lista De Precios"
        onChange={getInterestRate}
        size='small'
      >
        <MenuItem value="">
          <em>selecciona una lista</em>
        </MenuItem>
        {
          listas && listas.map(
            lista => {
              return (
                <MenuItem key={lista.ID} value={lista.ID}>{lista.Nombre}</MenuItem>
              )
            }
          )

        }
      </Select>
    </FormControl>

  );
}