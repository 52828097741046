import { getUser } from "../utils/Commons";

export function authHeader() {
    // return authorization header with jwt token
    let user = getUser()

    if (user && user.token) {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}