import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { OrderDetail } from "../../Order";
import { ImagesModal } from "../../../ui/components/ImagesModal";
import { GuideModal } from "./GuideModal";
import { COTDialog } from "./COTDialog";
import { DeliveryModal } from "./DeliveryModal";

import { Title, StyledPaper } from "../../../ui";
import { OrdersTextSearch } from "./OrdersTextSearch";
import { FromToDate } from "./FromToDate";
import { StatusFilter } from "./StatusFilter";

import { OrdersRowHeader } from "./OrdersRowHeader";
import { OrderRow } from "./OrderRow";
import { ConfirmationDialog } from "../../../ui";
import { NewCommentDialog } from "../../comments";
import { accountsService } from "../../../../services";

import { config, DIALOGS, ACTIONS } from "../../../../services/config";
import { contabiliumService } from "../../../../services";
import { useOrders } from "../hooks/useOrders";
import { StyledMenu } from "../../../ui/components/StyledMenu";

export function Orders({ readOnly }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [orderImages, setOrderImages] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    addGuide,
    addComment,
    filteredOrders,
    createBilling,
    createDelivery,
    createReceipt,
    deleteDelivery,
    dispatch,
    dispatchShow,
    isLoadingOrders,
    isLoadingOrdersSucces,
    setFromTo,
    setOrderPayed,
    setOrderState,
    setQuery,
    setStatusFilter,
    state,
    statusFilter,
    fromTo,
    query,
  } = useOrders();

  const navDelivery = (orderId) => window.open(`/remito/${orderId}`, '_blank');
  
  function cancelOrder() {
    try {
      setOrderState({ id: state.selectedId, data: config.orderStatus.canceled })
        .unwrap()
        .then((_) => contabiliumService.cancelOrder(state.selectedId));
    } catch (error) {
      //LUCAS: DESDE ACA!!!
    }
  }

  function handleDownload() {
    accountsService.getOrdersExport(fromTo.from, fromTo.to).then((res) =>
      res.blob().then((b) => {
        var file = window.URL.createObjectURL(b);
        window.location.assign(file);
      })
    );
  }

  function handleDownload2() {
    var filters = [];
    Object.keys(statusFilter).map((key) =>
      filters.push(`${key}=${statusFilter[key]}`)
    );
    filters = filters.join("&");
    accountsService
      .getProductsOrdersExport(fromTo.from, fromTo.to, filters)
      .then((res) =>
        res.blob().then((b) => {
          var file = window.URL.createObjectURL(b);
          window.location.assign(file);
        })
      );
  }

  function showImages(images) {
    setOrderImages(JSON.parse(images));
    dispatch({ type: ACTIONS.SHOW, payload: { dialog: DIALOGS.IMAGES } });
  }
  return (
    <>
      <StyledPaper>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Title>Ordenes de Venta</Title>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClick}>
              <FileDownloadIcon />
            </IconButton>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem key="Ordenes" onClick={handleDownload}>
                <ListItemIcon>
                  <SummarizeOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Ordenes</ListItemText>
              </MenuItem>
              <MenuItem key="ProductosPorOrdenes" onClick={handleDownload2}>
                <ListItemIcon>
                  <SummarizeOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Productos x Ordenes</ListItemText>
              </MenuItem>
            </StyledMenu>
          </Grid>
          <Grid item sm={3}>
            <OrdersTextSearch query={query} onQueryChange={setQuery} />
          </Grid>

          <Grid item sm={5}>
            <FromToDate fromTo={fromTo} onFromToChange={setFromTo} />
          </Grid>

          <Grid item sm={4}>
            <StatusFilter
              statusFilter={statusFilter}
              onStatusFilterChange={setStatusFilter}
            />
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper sx={{ minHeight: 650 }}>
        {isLoadingOrders && <h3>Cargando Ordenes</h3>}
        {isLoadingOrdersSucces && (
          <Table size="small">
            <OrdersRowHeader />

            <TableBody>
              {filteredOrders.map((order) => (
                <OrderRow
                  order={order}
                  key={order.id}
                  aproveOrder={(id) => dispatchShow(DIALOGS.APROVE_ORDER, id)}
                  billOrder={createBilling}
                  receiptOrder={createReceipt}
                  handleDetailOpen={(id) => dispatchShow(DIALOGS.DETAIL, id)}
                  markPayed={(id) => dispatchShow(DIALOGS.MARK_PAYED, id)}
                  showCancel={(id) => dispatchShow(DIALOGS.CANCEL, id)}
                  showImages={showImages}
                  navDelivery={navDelivery}
                  showGuide={(id) => dispatchShow(DIALOGS.ADD_GUIDE, id)}
                  showFinish={(id) => dispatchShow(DIALOGS.FINISH_ORDER, id)}
                  showDeliver={(id) =>
                    dispatchShow(DIALOGS.CREATE_DELIVERY, id)
                  }
                  showDeliveryDelete={(id) =>
                    dispatchShow(DIALOGS.DELETE_DELIVERY, id)
                  }
                  addComment={(id) => dispatchShow(DIALOGS.NEW_COMMENT, id)}
                  showCOT={(id) => dispatchShow(DIALOGS.CREATE_COT, id)}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </StyledPaper>

      <ConfirmationDialog
        title="Aprobar Orden de Venta"
        message="Estás a punto de aprobarr esta Orden. ¿Deséas continuar?"
        open={state.dialog === DIALOGS.APROVE_ORDER}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={() =>
          setOrderState({
            id: state.selectedId,
            data: config.orderStatus.aproved,
          })
        }
      />

      <ConfirmationDialog
        title="Cancelar Orden de Venta"
        message="Estás a punto de cancelar esta Orden. ¿Deséas continuar?"
        open={state.dialog === DIALOGS.CANCEL}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={cancelOrder}
      />

      <ConfirmationDialog
        title="Eliminar Remito"
        message="Estás a punto de eliminar el Remito asociado a la Order. ¿Deséas continuar?"
        open={state.dialog === DIALOGS.DELETE_DELIVERY}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={() => deleteDelivery(state.selectedId)}
      />

      <ConfirmationDialog
        title="Marcar la Orden Finalizada"
        message="Estás a punto de finalizar la Orden de Venta. Deseas continuar?"
        open={state.dialog === DIALOGS.FINISH_ORDER}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={() =>
          setOrderState({
            id: state.selectedId,
            data: config.orderStatus.finished,
          })
        }
      />

      <ConfirmationDialog
        title="Registrar Pago"
        message="Estás a punto de indicar el pago de la Orden de Venta. Deseas continuar?"
        open={state.dialog === DIALOGS.MARK_PAYED}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        callBack={() => setOrderPayed(state.selectedId)}
      />

      <OrderDetail
        open={state.dialog === DIALOGS.DETAIL}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        orderId={state.selectedId}
        readOnly={readOnly}
      />

      <ImagesModal
        open={state.dialog === DIALOGS.IMAGES}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        imagesArray={orderImages}
      />

      <DeliveryModal
        open={state.dialog === DIALOGS.CREATE_DELIVERY}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        createDelivery={(data) =>
          createDelivery({ id: state.selectedId, data })
        }
      />

      <GuideModal
        open={state.dialog === DIALOGS.ADD_GUIDE}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        handleSetGuide={(data) => addGuide({ id: state.selectedId, data })}
      />

      <NewCommentDialog
        open={state.dialog === DIALOGS.NEW_COMMENT}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        createComment={(data) => addComment({ id: state.selectedId, data })}
      />

      {state.selectedId && <COTDialog
        open={state.dialog === DIALOGS.CREATE_COT}
        handleClose={() => dispatch({ type: ACTIONS.HIDE })}
        id={state.selectedId}
      />}
    </>
  );
}
