import { useState } from "react";

const defaultState = {
  direccionEntrega: '',
  pisoDeptoEntrega: '',
  provinciaEntrega: 0,
  localidadEntrega: '',
  fechaEntrega: '',
  diasDeGracia: '',
  quienRecibe: '',
  telefonoRecibe: '',
  cpEntrega: ''
}

export function useShippingInfo() {

  const [shippingInfo, setShippingInfo] = useState(defaultState)

  const mustRequired = () => {
    return (
      shippingInfo.direccionEntrega ||
      shippingInfo.pisoDeptoEntrega ||
      shippingInfo.localidadEntrega ||
      shippingInfo.provinciaEntrega ||
      shippingInfo.cpEntrega) ? true : false
  }

  const isValidShipping = () => {


    if (mustRequired()) return shippingInfo.direccionEntrega !== defaultState.direccionEntrega &&
      shippingInfo.localidadEntrega !== defaultState.localidadEntrega &&
      shippingInfo.provinciaEntrega !== defaultState.provinciaEntrega &&
      shippingInfo.cpEntrega !== defaultState.cpEntrega
    else return true

  }

  const updateShipping = (info) => {
    let newState = {
      ...shippingInfo,
      direccionEntrega: info.direccionEntrega ? info.direccionEntrega : defaultState.direccionEntrega,
      pisoDeptoEntrega: info.pisoDeptoEntrega ? info.pisoDeptoEntrega : defaultState.pisoDeptoEntrega,
      provinciaEntrega: info.provinciaEntrega ? info.provinciaEntrega : defaultState.provinciaEntrega,
      localidadEntrega: info.localidadEntrega ? info.localidadEntrega : defaultState.localidadEntrega,
      fechaEntrega: info.fechaEntrega ? info.fechaEntrega : defaultState.fechaEntrega,
      diasDeGracia: info.diasDeGracia ? info.diasDeGracia : defaultState.diasDeGracia,
      quienRecibe: info.quienRecibe ? info.quienRecibe : defaultState.quienRecibe,
      telefonoRecibe: info.telefonoRecibe ? info.telefonoRecibe : defaultState.telefonoRecibe,
      cpEntrega: info.cpEntrega ? info.cpEntrega : defaultState.cpEntrega
    }
    setShippingInfo(newState)
  }
  return { shippingInfo, updateShipping, isValidShipping, mustRequired }
}