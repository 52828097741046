import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { config } from '../../../../services/config'

export const DiscountAlert = ({ openDialog, closeDialog }) => {

    <Dialog
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Atención"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                La bonificaciones superiores al {config.maxDiscountPerc} %
                deberán ser aprobadas por un supervisor.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} autoFocus variant="contained">
                Continuar
            </Button>
        </DialogActions>
    </Dialog>
}