import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { StyledPaper, Title } from "../../../ui";
import { ProductSearch } from "../../Products";
import { Conditions } from './Conditions'
import { ProductMeta } from "./ProductMeta";
import { QuotationItems } from './QuotationItems';

import { useQuotation, useProductMeta } from '../hooks';

export function QuotationEditor() {
  const { id } = useParams();

  const {
    quotation, getQuotation, saveQuotation,
    selectedProduct, selectProduct, addProduct,
    setName, setBuilding, setAddress, setPhone,
    setContactPerson, setAttention
  } = useQuotation()
  const { openMeta, showMeta, hideMeta } = useProductMeta()

  const { handleSubmit, setValue } = useForm({
    defaultValues: {
      name: '',
      building: '',
    }
  });

  function productSelected(product) {
    selectProduct(product)
    showMeta()
  }

  function handleAddProduct(data) {
    addProduct(data)
    hideMeta()
  }

  useEffect(() => { if (id) getQuotation(id) }, [id])

  /*   useEffect(() => {
      setValue('name', quotation.name)
      setValue('building', quotation.building)
      setValue('attention', quotation.attention)
      setValue('address', quotation.address)
      setValue('phone', quotation.phone)
      setValue('contactPerson', quotation.contactPerson)
      setValue('localCurrency', quotation.localCurrency)
  
    }, [quotation]) */


  function onSubmit(data) {
    console.log(data)
    saveQuotation()
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container spacing={2}>

          <Grid item md={3}>
            <StyledPaper>
              <Typography variant="h6">
                <ProductSearch addProduct={productSelected} enableNoStock={true} />
              </Typography>
            </StyledPaper>
          </Grid>

          <Grid item md={9}>
            <form id="quotation-info" onSubmit={handleSubmit(onSubmit)}>

              <StyledPaper>
                <Title>
                  🏗️ Datos de Cliente
                </Title>
                <input type="hidden" id="IdCliente" name="IdCliente" value={quotation.idCliente} />
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={6}>
                    <label htmlFor="name">Razón Social</label>
                    <TextField id="name" name="name"
                      fullWidth size="small"
                      value={quotation.name}
                      onChange={e => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="attention">Atención</label>
                    <TextField
                      name="attention"
                      id="attention"
                      fullWidth size="small"
                      value={quotation.attention}
                      onChange={e => setAttention(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="building">Obra</label>
                    <TextField
                      name="building"
                      id="building"
                      fullWidth size="small"
                      value={quotation.building}
                      onChange={e => setBuilding(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="address">Dirección</label>
                    <TextField
                      name="address"
                      id="address"
                      fullWidth size="small"
                      value={quotation.address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="contactPerson">Persona de Contacto</label>
                    <TextField
                      name="contactPerson"
                      id="contactPerson"
                      fullWidth size="small"
                      value={quotation.contactPerson}
                      onChange={e => setContactPerson(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="phone">Telefono</label>
                    <TextField
                      name="phone"
                      id="phone"
                      fullWidth size="small"
                      value={quotation.phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </StyledPaper>

              <StyledPaper>
                <Grid container mb={2}>
                  <Grid item xs={6}>
                    <Title>
                      🛒 Carrito
                    </Title>
                  </Grid>
                  <Grid item xs={6} container
                    direction="column"
                    alignItems="flex-end">
                    <Stack direction={'row'} alignItems={'center'}>
                      <label>
                        <input type="checkbox" value={quotation.localCurrency} />
                        Precios expresados en ARS
                      </label>
                    </Stack>
                  </Grid>
                </Grid>
                <QuotationItems quotation={quotation} handleEdit={productSelected} />
              </StyledPaper>

              <StyledPaper>
                <Title>
                  *️⃣ Observaciones
                </Title>
                <Conditions quotation={quotation} />
              </StyledPaper>

              <StyledPaper>
                <Grid container spacing={2}>
                  <Grid item xs={8} />
                  <Grid item xs={2} align='right'>
                    <Button variant="outlined" fullWidth>Cancelar</Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button type="submit" variant="contained" form="quotation-info" fullWidth>Guardar</Button>
                  </Grid>
                </Grid>
              </StyledPaper>
            </form>
          </Grid>

        </Grid>
      </Box>
      <ProductMeta open={openMeta} handleClose={hideMeta} product={selectedProduct} addProduct={handleAddProduct} />
    </>
  )
}