import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

export const FormInputDropdown = ({ name, control, label, options, required, disabled }) => {
  
  const parseOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.ID} value={option.ID}>
          {option.Nombre}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl size={"middle"} fullWidth>
      <InputLabel id="id-select">{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            defaultValue=""
            value={(options && options.length > 0) ? value ? value : ""  : ""}
            labelId="id-select"
            label={label}
            required={required}
            disabled={disabled}
          >
            {parseOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};