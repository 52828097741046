import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../services/config";
import { getToken } from "../../utils/Commons";

const APP_ROUTES_ENDPOINT = '/app-routes'
const USER_GROUPS_ENDPOINT = '/user-groups'
const ROUTES_TAG = 'Routes'

//TODO: Repetida de restApiSLice - abstraer
const authHeader = () => {
    var token = getToken();
    if (token) return { Authorization: "Bearer " + token };
    return {};
  };
  
  const getAppRoutes = {
    query: () => ({
      url: APP_ROUTES_ENDPOINT,
      headers: authHeader(),
    }),
    providesTags: [ROUTES_TAG],
  };
  
  const getUserGroups = {
    query: () => ({
      url: USER_GROUPS_ENDPOINT,
      headers: authHeader(),
    }),
    providesTags: [ROUTES_TAG],
  };
  
  const getAccounts = {
    query: () => ({
      url: config.apiUrl,
      headers: authHeader(),
    }),
    providesTags: [ROUTES_TAG],
  };

  export const accountsApiSlice = createApi({
    reducerPath: "accounts",
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: [ROUTES_TAG],
    endpoints: (builder) => ({
        getAccounts: builder.query(getAccounts),
        getAppRoutes: builder.query(getAppRoutes),
        getUserGroups: builder.query(getUserGroups),
    }),
});

export const {
    useGetAccountsQuery,
    useGetAppRoutesQuery,
    useGetUserGroupsQuery,
} = accountsApiSlice;