import * as React from 'react';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography';

export function ProductCard({producto, addProductClicked, interestRate, enableNoStock}) {
  
  let foto = producto.Foto === null ?
    'https://app.contabilium.com/files/usuarios/no-producto.png' :
    "https://app.contabilium.com/files/explorer/" + producto.Foto

  const handleClick = (e) =>{
    e.preventDefault()
    /**
     * Aplicando la tasa de interés de la lista de precios.
     * Contabilium almacenará el Precio, el Iva y la Bonficacion, por eso se 
     * recalcula el PrecioFinal en funcion del ajuste por redondeo
     * y el Precio descontando el Iva. 
     * El PrecioFinal es el que se muestra en el carrito.
     */
    let PrecioFinal = roundPrice(producto.PrecioFinal)
    let Precio = PrecioFinal * 100 / (100 + producto.Iva)
    let p = {...producto, PrecioFinal, Precio}
    addProductClicked(p)
  }

  const roundPrice = (price) => {
    return (Math.round((price + price * interestRate /100) / 10) * 10).toFixed(2)
  }

  return (

    <Card variant="outlined" sx={{ width: '100%', marginTop: 1 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={foto}
        title={producto.Nombre}
      />
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {producto.Nombre}
        </Typography>
        <Typography variant="h6" component="div" color="text.secondary">
          ${roundPrice(producto.PrecioFinal)}
        </Typography>
        <Typography variant="body2">
          {producto.Codigo} - Stock: {producto.Stock} unid.
        </Typography>
      </CardContent>
      <CardActions>

        <Button sx={{ marginLeft: 'auto' }} size="small" variant='outlined' disabled={(!enableNoStock && producto.Stock===0)}
          onClick={handleClick}>Agregar</Button>

      </CardActions>
    </Card>

  );
}