

import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import AddComment from '@mui/icons-material/AddComment';
import CancelIcon from '@mui/icons-material/Cancel';

import { AttachmentsChip } from '../../OrdersList/components/AttachmentsChip';
import { OrderInfoIcons } from '../../OrdersList/components/OrderInfoIcons';
import { StatusChip } from '../../OrdersList/components/StatusChip';
import { currencyFormat } from '../../../../utils/Commons';
import { config } from '../../../../services/config';

import { UploadImages } from './UploadImages';

export function OrdersTableRow(
  {
    order, handleDetailOpen, showImages,
    navDelivery, selectFile, showCancel,
    showNewComment
  }

) {

  const canCancel = order.estado !== config.orderStatus.finished &&
    order.estado !== config.orderStatus.canceled &&
    order.comprobanteContabilium == null

  return (

    <TableRow>

      <TableCell align='center' size='small'> {/**Estado */}
        <StatusChip status={order.estado} />
      </TableCell>

      <TableCell>  {/** Nro de Orden en Contabilum */}
        <Link color="primary" href="#" onClick={
          (e) => {
            e.preventDefault()
            handleDetailOpen(order.id)
          }
        }>
          {order.ordenContabiliumNro}
        </Link>
      </TableCell>

      <TableCell>{order.nombre}</TableCell>{/** Cliente */}
      <TableCell>{new Date(order.fechaEmision).toLocaleDateString('es-ar')}</TableCell>
      <TableCell align="right">{currencyFormat(Number(order.total))}</TableCell>
      <TableCell>{order && order.numeroGuia}</TableCell>
      <TableCell>
        <AttachmentsChip
          order={order}
          showImages={showImages}
          showDelivery={navDelivery}
        />
      </TableCell>
      <TableCell><OrderInfoIcons order={order} /></TableCell>
      <TableCell size='small' align='center'>
        <UploadImages order={order} selectFile={selectFile} />
        {/** AGREGAR COMENTARIO */}
        {order.comments.length === 0 && <Tooltip title="Agregar comentarios a una orden en curso">
          <IconButton
            id={order.id}
            component="span"
            color="warning"
            onClick={() => showNewComment(order.id)}
          >
            <AddComment />
          </IconButton>
        </Tooltip>
        }
        {/** CANCELAR */}
        {
          canCancel &&
          <Tooltip title="Cancelar esta Orden">
            <IconButton
              id={order.id}
              component="span"
              color="warning"
              onClick={() => showCancel(order.ordenContabilium)}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        }
      </TableCell>
    </TableRow>
  )
}