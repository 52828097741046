import { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import GroupsIcon from "@mui/icons-material/Groups";
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { getUser } from "../../../../../utils/Commons";

const ventasRoutes = [
  { label: "Ventas", route: "/ventas", key: "ventas", icon: <BusinessIcon /> },
  {
    label: "Administracion",
    route: "/administracion",
    key: "administracion",
    icon: <BusinessIcon />,
  },
  {
    label: "Mis Ventas",
    route: "/mis-ventas",
    key: "mis-ventas",
    icon: <BusinessCenterIcon />,
  },
  {
    label: "Nueva Venta",
    route: "/",
    key: "nueva-venta",
    icon: <AddCircleOutlineIcon />,
  },
];

const quotesRoutes = [
  {
    label: "Cotizaciones",
    route: "/cotizaciones",
    key: "cotizaciones",
    icon: <BusinessIcon />,
  },
  {
    label: "Nueva Cotización",
    route: "/nueva-cotizacion",
    key: "nueva-cotizacion",
    icon: <AddCircleOutlineIcon />,
  },
  {
    label: "Productos",
    route: "/product-meta",
    key: "productos",
    icon: <InventoryIcon />,
  },
];

const rootRoutes = [
  {
    label: "Cuentas",
    route: "/accounts",
    key: "accounts",
    icon: <PersonIcon />,
  },
  {
    label: "Grupos",
    route: "/user-groups",
    key: "groups",
    icon: <GroupsIcon />,
  },
  {
    label: "Rutas App",
    route: "/app-routes",
    key: "app-routes",
    icon: <ForkRightIcon />,
  },
];

export function useMenues() {
  const [menuVentas, setMenuVentas] = useState();
  const [menuQuotations, setMenuQuotations] = useState();
  const [menuRoot, setMenuRoot] = useState();
  const userRoutes = getUser().appRoutes;

  useEffect(() => {
    if (!userRoutes) return;
    setMenuVentas(ventasRoutes.filter((r) => userRoutes.includes(r.key)));
    setMenuRoot(rootRoutes.filter((r) => userRoutes.includes(r.key)));
    setMenuQuotations(quotesRoutes.filter((r) => userRoutes.includes(r.key)));
  }, []);

  return { menuRoot, menuVentas, menuQuotations };
}
