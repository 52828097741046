import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "./AppBar";
import { DrawerHeader } from "./DrawerHeader";
import { AppDrawer } from "./AppDrawer";
import { Main } from "./Main";
import logo from "./app-bar-logo.png";
/* import { AppBarNotifications } from "../AppBarNotifications"; */

export function AppLayout(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} color="grey">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="menu de opciones"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="KALITEKNOS" />
          {/* <AppBarNotifications /> */}
        </Toolbar>
      </AppBar>

      <AppDrawer
        open={open}
        handleDrawerClose={() => setOpen(false)}
        theme={theme}
      />

      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
