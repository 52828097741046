import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { config } from "../../../services/config";
import { getStatusLabel } from "../../../utils/Commons";

export function StatusChip2({ status, text }) {
  const { aproved, processing, canceled, finished } = config.orderStatus;

  let emoji = "🟠"; //pending by default

  if (status === canceled) emoji = "🔴";
  if (status === aproved) emoji = "🔵";
  if (status === processing) emoji = "🟢";
  if (status === finished) emoji = "🟣";

  return (
    <Tooltip title={getStatusLabel(status)}>
      <Typography style={{ fontSize: "0.95em" }}>{`${emoji} - ${text}`}</Typography>
    </Tooltip>
  );
}
