import { useEffect, useState } from "react";
import { accountsService, contabiliumService } from '../../../../services';
import { config } from '../../../../services/config'
import { useShippingInfo } from "./useShippingInfo";

const emptyOrder = {
  selectedCustomer: {},

  idCliente: null,
  nombre: null,
  nroDoc: null,
  fechaEmision: null,
  total: 0,

  items: [],
  details: {
    condicionVenta: null,
    observaciones: null,
    canal: null,  //defaults to b2b?
  }

}

export function useOrder({ id, onSaved }) {

  const [order, setOrder] = useState(emptyOrder)
  const [orderItems, setOrderItems] = useState([]);

  const [customer, setCustomer] = useState();
  const [notification, setNotification] = useState()

  const { 
    shippingInfo, 
    updateShipping, 
    isValidShipping, 
    mustRequired 
  } = useShippingInfo()

  const [details, setDetails] = useState({
    condicionVenta: '',
    canal: 'B2C',
    observaciones: ''
  })

  const [saveReady, setSaveReady] = useState(false);
  const [mustAprove, setMustAprove] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openDetail, setOpenDetail] = useState(false);
  const [orderId, setOrderId] = useState();


  const resetNotification = () => { setNotification() }

  const recalculate = (items) => {
    let sumPrices = 0
    let sumIva = 0
    let sumQty = 0

    items.forEach(i => {
      i.SubTotal = (i.Cantidad * i.PrecioFinal) * (1 - (i.Bonificacion / 100))
      sumPrices += i.SubTotal
      sumIva += i.SubTotal * (i.Iva / 100)
      sumQty += i.Cantidad
    })
    let orderState = { ...order, total: sumPrices, iva: sumIva, cantidad: sumQty }
    setOrder(orderState)
    setSaveReady((items.length > 0 && details.condicionVenta !== '' && customer && isValidShipping))
  }

  const calcSubTotal = ({ Precio, Iva, Cantidad, Bonificacion }) => {
    return Precio * (1 + (Iva / 100)) * Cantidad * (1 - (Bonificacion / 100))
  }

  useEffect(() => {
  
    if (!id) return //no id es una Orden nueva
    accountsService.getOrder(id)
      .then(
        r => {
          if (r.success) {
            let data = r.data
            setOrder(data);
            contabiliumService.getCliente(data.idCliente)
              .then(c => {
                if (c.success) setCustomer(c.data)
                else {
                  setNotification({ message: 'No se pudo obtener la información del Cliente', type: 'error' })
                }
              });

            setDetails({
              condicionVenta: data.condicionVenta,
              canal: data.canal,
              observaciones: data.observaciones ? data.observaciones : ''
            })

            setOrderItems(data.order_items)
            updateShipping(data)
          }
        }
      )
  }, [id])

  const updateDetails = (info) => {
    setDetails(info)
    setSaveReady((orderItems.length > 0 && info.condicionVenta !== '') && customer && isValidShipping)
  }

  const addProduct = (product) => {
    let id = orderItems.length + 1
    let state = [...orderItems]
    state.push({
      id,
      Cantidad: 1,
      Bonificacion: 0,
      ...product
    })
    recalculate(state);
    setOrderItems(state);
  }

  const handleQtyChange = (id, qty) => {
    let items = orderItems.map(
      item => {
        let SubTotal = calcSubTotal(item)
        if (item.id !== id) return item
        else return { ...item, Cantidad: qty, SubTotal }
      }
    )
    setOrderItems(items)
    recalculate(items)
  }

  const handleDiscountChange = (id, dis) => {
    if (dis > config.maxDiscountPerc) {
      setOpenDialog(true)
      setMustAprove(true)
    }
    let items = orderItems.map(
      item => {
        let SubTotal = calcSubTotal(item)
        if (item.id !== id) return item
        else return { ...item, Bonificacion: dis, SubTotal }
      }
    )
    setOrderItems(items)
    recalculate(items)
  }

  const handleItemDelete = id => {
    let items = orderItems.filter(
      item => item.id !== id
    )
    setOrderItems(items)
    recalculate(items)
  }
  const closeDialog = () => {
    setOpenDialog(false)
  }

  const selectCustomer = (customer) => {
    setCustomer(customer)
    setSaveReady((orderItems.length > 0 && details.condicionVenta !== '' && isValidShipping))
  }

  const parseItems = () => {
    return orderItems.map(
      i => {
        return {
          idConcepto: i.Id,
          descripcion: i.Nombre,
          cantidad: i.Cantidad,
          precioUnitario: i.Precio,
          bonificacion: i.Bonificacion,
          SKU: i.Codigo
        }
      }
    )
  }

  const saveOrder = () => {
    let today = new Date().toJSON()
    let items = parseItems()

    let saveCandidate = {
      idCliente: customer.Id,
      nombre: customer.RazonSocial,
      nroDoc: parseInt(customer.NroDoc),
      fechaEmision: today,
      ...details,
      items,
      total: order.total,
      ...shippingInfo,
      provincia: shippingInfo.provincia !== '' ? shippingInfo.provincia : null,
      diasDeGracia: shippingInfo.diasDeGracia !== '' ? shippingInfo.diasDeGracia : null,
      mustAprove
    }

    if (!id) {
      contabiliumService.saveOrder(saveCandidate)
        .then(saved => {
          if (saved.success) {
            saveCandidate.ordenContabilium = saved.data
            //traer la nueva orden para consultar en Nro de Orden
            contabiliumService.getOrder(saved.data)
              .then(
                o => {
                  if (o.success) {
                    saveCandidate.ordenContabiliumNro = o.data.NumeroOrden
                    accountsService.saveOrder(saveCandidate)
                      .then(so => {
                        if (so.success) onSaved({ message: "La Orden de Venta se guardó correctamente", type: "success" })
                        else onSaved({ message: so.message, type: "error" })
                      })
                  }
                },
                err => { onSaved({ message: 'Error al obtener la orden generada en Contabilium', type: 'error' }) }
              )
          } else {
            if (saved.status === 400) onSaved({ message: 'Error al guardar la order. Verifique es stock de los productos!', type: "error" })
            else onSaved({ message: 'Ocurrio un error al comunicarse con Contabilium', type: 'error' })
          }
        })
    }
    else {
      accountsService.updateOrder(id, saveCandidate)
        .then(
          results => {
            if (results.success) onSaved({ message: "La Orden de Venta se guardó correctamente", type: "success" })
            else onSaved({ message: "Oh, oh... Algo falló al actualizar la Orden", type: "error" })
          }
        )
    }


  }

  const showDetail = (orderId) => {
    setOrderId(orderId)
    setOpenDetail(true)
  }
  const hideDetail = () => {
    setOpenDetail(false)
  }

  return {
    order, saveOrder,
    customer, selectCustomer,
    details, updateDetails,
    orderItems, addProduct, handleItemDelete, handleDiscountChange, handleQtyChange,
    shippingInfo, updateShipping, isValidShipping, mustRequired,
    saveReady, openDialog, closeDialog,
    notification, resetNotification,
    openDetail, showDetail, hideDetail, orderId
  }
}