import * as React from 'react';
import AttachMoney from '@mui/icons-material/AttachMoney'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import { IconTitle, SelectCanales, SelectCondicionesDeVenta } from '../../../ui';

export default function DetailsPanel({details, updateDetails, readOnly}) {

  return (
    <Box sx={{ marginBottom: 3 }}>
      <IconTitle icon={AttachMoney}>Detalles de la Venta</IconTitle>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl required fullWidth disabled={readOnly}>
            <SelectCondicionesDeVenta
              selected={details.condicionVenta}
              disabled={readOnly}
              changeCondicionDeVenta={value => updateDetails({ ...details, condicionVenta: value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <SelectCanales 
          selected={details.canal} 
          disabled={readOnly}
          changeCanal={value => updateDetails({ ...details, canal: value })} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            multiline
            variant='outlined'
            label="Observaciones"
            id="observaciones"
            onChange={e => updateDetails({ ...details, observaciones: e.target.value })}
            autoComplete='off'
            rows={4}
            value={details.observaciones}
            disabled={readOnly}
          />
        </Grid>
      </Grid>


    </Box>
  )
}
