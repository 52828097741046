import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(2.5),
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
}));
//textAlign: 'center',