import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import { ProductSearch } from '../../Products';
import { CustomerSearch } from '../../Customer';
import { OrderItems } from './OrderItems'
import { ActionButtons } from './ActionButtons'
import { ShippingForm } from './ShippingForm';
import { StyledPaper } from '../../../ui';

import DetailsPanel from './DetailsPanel';
import { DiscountAlert } from './DiscountAlert';
import { useOrder } from '../hooks/useOrder'
import { useNotification } from '../../../notifications';
import { useNavigate } from 'react-router-dom';


export function OrderEditor({ id }) {
  const navigate = useNavigate()

  const onSaved = (message) => {
    displayNotification(message)
    if (message.type === "success") navigate(0)
  }
  const { displayNotification } = useNotification()
  const {
    order, saveOrder,
    customer, selectCustomer,
    details, updateDetails,
    orderItems, addProduct, handleItemDelete, handleDiscountChange, handleQtyChange,
    shippingInfo, updateShipping, mustRequired,
    saveReady,
    openDialog, closeDialog,
    notification, resetNotification
  } = useOrder({ id, onSaved });

  if (notification) {
    displayNotification(notification)
    resetNotification()
  }
  
  function guardarOrden() {
    saveOrder()
  }

  function agregarProducto(product) {
    addProduct(product)
    displayNotification({ message: "Se agrego el producto" + product.Nombre, type: "success" })
  }
  return (
    <div className="NewOrder">

      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <StyledPaper children={<ProductSearch addProduct={agregarProducto} />} />
          </Grid>

          <Grid item xs={12} sm={8} md={9} lg={8}>
            <StyledPaper children={
              <CustomerSearch
                selected={customer}
                selectCustomer={selectCustomer}
              />
            } />

            <StyledPaper children={<DetailsPanel details={details} updateDetails={updateDetails} />} />

            <StyledPaper children={
              <OrderItems
                orderItems={orderItems}
                total={order.total}
                iva={order.iva}
                cantidad={order.cantidad}
                handleQtyChange={handleQtyChange}
                handleDiscountChange={handleDiscountChange}
                handleItemDelete={handleItemDelete}
              />}
            />
            <StyledPaper children={<ShippingForm shippingInfo={shippingInfo} updateShipping={updateShipping} mustRequired={mustRequired} />} />
            <StyledPaper children={<ActionButtons saveOrder={guardarOrden} canSave={saveReady} />} />
          </Grid>
        </Grid>
      </Box>

      <DiscountAlert openDialog={openDialog} closeDialog={closeDialog} />
    </div>
  );
}

