import { Box, Grid, Typography } from '@mui/material';
import { currencyFormat } from '../../../../utils/Commons';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton';

function EmptyList() {
  return (

    <Grid item container
      direction="column" 
      alignContent="center" 
      
      style={{border:'1px solid #cecece', marginTop: '2em', marginLeft:'1em', paddingTop:'3em', borderRadius:'10px'}}
    >
      <Grid item xs={5} display="flex" justifyContent="flex-end">
        <Typography variant="h2">🔎</Typography>
      </Grid>
      <Grid item xs={5} display="flex" justifyContent="flex-start">
        <Typography variant='h4' pl={2} pt={1} sx={{ fontWeight: 800 }}>Todavía no hay productos...</Typography>
      </Grid>
    </Grid>
  )
}

export function QuotationItems({ quotation, handleDelete, handleEdit }) {

  return (
    <>
      {quotation &&
        <Grid container spacing={2}>

          <Grid item xs={6} />

          <Grid container item xs={6}>
            <Grid item xs={2} align="right">
              <Typography sx={{ fontWeight: 800 }}>Cant.</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography sx={{ fontWeight: 800 }}>Precio</Typography>
            </Grid>
            <Grid item xs={2} align="right">
              <Typography sx={{ fontWeight: 800 }}>Bonif.</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography sx={{ fontWeight: 800 }}>Total</Typography>
            </Grid>
            <Grid item xs={2} align="right">
            </Grid>
          </Grid>

          {quotation.items.length === 0 && <EmptyList />}

          {
            quotation.items.map(
              (product) => (
                <Grid item container xs={12} key={product.id}>

                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 800, marginBottom: 2 }}>
                      {product.vendor} - {product.codigo} - {product.name}
                    </Typography>
                  </Grid>

                  <Grid container item xs={6}>
                    <Grid item xs={4}>
                      <Box
                        component="img"
                        sx={{ width: "100%", height: "75%", marginRight: 1 }}
                        src={product.foto}
                      />
                    </Grid>

                    <Grid item container xs={8}>
                      <Typography>
                        {product.description}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={6} display="flex">
                    <Grid item xs={2} align="right">
                      <Typography>
                        {
                          // por alguna razon el form de productMeta devuelve la cantidad como string   
                          product && parseInt(product.qty)
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography>
                        {product && currencyFormat(parseFloat(product.price))}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="right">
                      <Typography>
                        {product && product.discount}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography>
                        {product && currencyFormat(parseFloat(product.price) * parseInt(product.qty))}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="center">
                      <IconButton aria-label="editar" color="primary" onClick={() => handleEdit(product)}>
                        <EditIcon fontSize='small' />
                      </IconButton>
                      <IconButton aria-label="eliminar" color="primary" onClick={() => handleDelete(product)}>
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )
          }
          <Grid item xs={11} textAlign='right'>
            <Typography variant='h6'>Subtotal: {currencyFormat(parseFloat(quotation.subtotal))}</Typography>
            <Typography variant='h6'>Iva: {currencyFormat(parseFloat(quotation.iva))}</Typography>
            <Typography variant='h6'>Total: {currencyFormat(parseFloat(quotation.total))}</Typography>

          </Grid>
        </Grid>
      }
    </>
  )
}