import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuotation } from "../hooks";

import {
  Document,
  Image,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import logo from "../assets/logo-med.png";
import noProduct from "../assets/no-producto.png";
//import anafe from "../assets/concepto-10419373.png"
import anafe from "../assets/concepto-10.png"

import { currencyFormat, padWithLeadingZeroes } from "../../../../utils/Commons";

Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2' })

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "#030303",
    paddingLeft: 30,
    paddingRight: 20,
    paddingBottom: 50,
    paddingTop: 50,
    fontSize: "9",
  },
  title: { fontWeight: "bold" },
  titleRight: { fontWeight: "bold", textAlign: "right" },
});

export function QuotationPDF() {

  const { id } = useParams();
  const { quotation, getQuotation } = useQuotation();
  const { title, titleRight, page } = { ...styles };
  const todayES_ar = new Date(quotation.updatedAt).toLocaleDateString("es-ar")
  const leftText = ['Presupuesto', 'Fecha', 'CUIT', 'Ing. Brutos', 'Inicio Act.', 'Responsable Inscripto']
  const rightText = ["KPC-" + padWithLeadingZeroes(quotation.id, 6), todayES_ar, '30-60881265-9', '901-947240-3', '17/9/1983']

  useEffect(() => { if (id) getQuotation(id) }, [id]);

  return (
    <PDFViewer width={"100%"} height={"900"}>
      <Document
        title={"Cotizacion KPC-" + padWithLeadingZeroes(quotation.id, 6)}
        author="Kaliteknos"
        subject={"Cotizacion para " + quotation.name + quotation.building !== '' ? " - " + quotation.building : "" + " - " + todayES_ar}
        language="ES-ar"
        pageMode="useThumbs"
      >
        <Page size={"A4"} style={page}>
          <View style={{ flexDirection: "row", paddingBottom: 8, }}>

            <View style={{ width: '60%', paddingLeft: 50 }}>
              <Image src={logo} style={{ width: "55%", height: "auto" }} />
            </View>

            <View style={{ width: '40%', flexDirection: "row", paddingTop: 20 }}>
              <View style={{ width: "50%" }}>
                {leftText.map((text) => <Text style={title}>{text}</Text>)}
              </View>
              <View style={{ width: "50%" }}>
                {rightText.map((text) => <Text style={titleRight}>{text}</Text>)}
              </View>
            </View>

          </View>

          <View style={{ marginTop: 20, marginBottom: 20, fontSize: "10" }}>
            <Text>Cliente: {quotation.name}</Text>
            <Text>Obra: {quotation.building}</Text>
            <Text>Attn: {quotation.attention}</Text>
          </View>

          <View>
            <View style={{ flexDirection: "row", fontSize: "8" }}>
              <Text style={{ width: "70%", padding: 4, borderBottom: 0.7, borderColor: "gray", textAlign: 'center' }}>
                Marca, modelo y características del Producto
              </Text>
              <View style={{ width: "30%", padding: 4, flexDirection: "row", border: 0.7, borderColor: "gray", }}>
                <Text style={{ width: "6%", textAlign: "center" }}>uni.</Text>
                <Text style={{ width: "47%", textAlign: "right" }}>Precio x uni.</Text>
                <Text style={{ width: "47%", textAlign: "center" }}>Total</Text>
              </View>
            </View>

            {quotation.items.map((item) => {

              return (
                <View style={{ flexDirection: "row", borderBottom: 0.7, borderColor: "gray", }} wrap={false}>
                  <View style={{ width: "70%", padding: 3 }}>
                    <Text style={{ fontWeight: "bold", margin: 3 }}>
                      {item.vendor} - {item.codigo} - {item.name}
                    </Text>

                    <View style={{ flexDirection: "row" }}>
                      <Image src={anafe} style={{ width: "100", height: "auto" }} />
                      <Text style={{ width: "400", marginTop: '8' }}>{item.description}</Text>
                    </View>
                  </View>

                  <View style={{ width: "30%", padding: 3, fontSize: "8", flexDirection: "row", }}>
                    <Text style={{ width: "6%", textAlign: "center", margin: 3 }}>{item.qty}</Text>
                    <Text style={{ width: "47%", textAlign: "right", margin: 3 }}>{currencyFormat(parseFloat(item.price))}</Text>
                    <Text style={{ width: "47%", textAlign: "right", margin: 3 }}>{currencyFormat(parseFloat(item.price) * parseInt(item.qty))}</Text>
                  </View>
                </View>
              );
            })}

            <View style={{ fontSize: 10, fontWeight: 'bold', padding: 16 }}>
              <Text style={{ textAlign: 'right' }}>Subotal: {currencyFormat(parseFloat(quotation.subtotal))}</Text>
              <Text style={{ textAlign: 'right' }}>IVA: {currencyFormat(parseFloat(quotation.iva))}</Text>
              <Text style={{ textAlign: 'right' }}>Total: {currencyFormat(parseFloat(quotation.total))}</Text>
            </View>

            <View>
              <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Observaciones</Text>
              <Text>Forma de pago: {quotation.paymentMethod}</Text>
              <Text>Validez de la oferta: {quotation.tender}</Text>
              <Text>Garantía del fabricante: {quotation.vendorWarranty}</Text>
              <Text>Envío: {quotation.shipping}</Text>
              <Text>Los precios de este presupuesto estan expresados en Dolares Estadounidences (USD)</Text>

            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>

    /*

    <Image src={{ uri: 'https://app.contabilium.com/files/explorer/38990/Productos-Servicios/concepto-10419373.png', 

    https://app.contabilium.com/files/explorer/38990/Productos-Servicios/concepto-10419373.png

    */
  );
}
