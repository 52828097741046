import React, { useState } from 'react';
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel';
import Person from '@mui/icons-material/Person'
import SearchIcon from '@mui/icons-material/Search';

import { CustomerSearchResults } from './CustomerSearchResults'
import { useNotification } from '../../../notifications'
import { useForm } from "react-hook-form";

import { contabiliumService } from '../../../../services';
import { IconTitle } from '../../../ui';
import { NewCustomer } from './NewCustomer'
import { CustomerData } from './CustomerData';

export function CustomerSearch({ selected: customer, selectCustomer }) {

  const methods = useForm({ defaultValues: { qry: '' } });
  const { handleSubmit, register } = methods;

  const [openResults, setOpenResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [openNewCustomer, setOpenNewCustomer] = useState(false);
  const { displayNotification } = useNotification();


  const startQuery = async (qry) => {
    let res = await contabiliumService.searchClientes(10, qry)
    if (res.success) {
      setSearchResults(res.data.Items);
      handleResultsOpen();
    }
    else displayNotification({
      type: "error",
      message: 'Oh oh... falló la comunicacion con Contabilium'
    });
  }

  const customerSelected = (customer) => {
    selectCustomer(customer) //notify parent
    handleResultsClose();
  }

  const handleResultsOpen = () => {
    setOpenResults(true);
  }

  const handleResultsClose = () => {
    setOpenResults(false)
  }

  const handleNewCustomer = () => {
    setOpenNewCustomer(true)
  }

  const handleNewCustomerClose = () => {
    setOpenNewCustomer(false)
  }

  const onSubmit = (data) => {
    startQuery(data.qry)
  }
  return (
    <Box sx={{ marginBottom: 3 }}>
      <IconTitle icon={Person}>Cliente</IconTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ pl: 2, pr: 2, mb: 2 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="Buscar Productos">Buscar Clientes</InputLabel>
          <Input
            id="buscar-clientes"
            name="qry"
            {...register('qry')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton type='submit'><SearchIcon /></IconButton>
              </InputAdornment>}
          />
        </FormControl>
      </form>

      <CustomerData customer={customer} />

      <NewCustomer
        open={openNewCustomer}
        handleClose={handleNewCustomerClose}
        customerSelected={customerSelected}
      />

      <CustomerSearchResults
        open={openResults}
        clientes={searchResults}
        handleClose={handleResultsClose}
        handleNewCustomer={handleNewCustomer}
        customerSelected={customerSelected}
      />
    </Box>
  )
}

export default CustomerSearch