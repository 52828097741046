import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const OrdersTableHead = () =>
  <TableHead>
    <TableRow>
      <TableCell size='small' align='center'>Est</TableCell>
      <TableCell size='small'>Nro</TableCell>
      <TableCell>Cliente</TableCell>
      <TableCell size='small'>Fecha</TableCell>
      <TableCell size='small' align="right">Total</TableCell>
      <TableCell size='small' align="center"># Guía</TableCell>
      <TableCell size='small' align="center">Documentos</TableCell>
      <TableCell size='small' align="center">Alertas</TableCell>
      <TableCell size='small' align="center">Acciones</TableCell>
    </TableRow>
  </TableHead>