import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";

import { useQuotations } from "../hooks";
import { QuotationsList } from "./QuotationsList";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function EmptyList() {
  return (
    <Grid
      container
      spacing={4}
      padding={4}
      direction="column"
      alignContent="center"
      alignItems="center"
      mt={5}
      mb={5}
    >
      <Grid item xs={5} display="flex" justifyContent="flex-end">
        <Typography variant="h2">🔎</Typography>
      </Grid>
      <Grid item xs={5} display="flex" justifyContent="flex-start">
        <Typography variant="h4" sx={{ fontWeight: 800 }}>
          No se encontraron cotizaciones...
        </Typography>
      </Grid>
    </Grid>
  );
}
export function Quotations() {
  const { loading, quotations } = useQuotations();
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" key="product-meta">
          Cotizaciones
        </Typography>
        {loading && <Typography variant="h5">Cargando</Typography>}
        {quotations.length === 0 && !loading && <EmptyList />}
        {quotations.length > 0 && !loading && (
          <QuotationsList quotations={quotations} />
        )}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={4}
        >
          <Fab
            size="small"
            color="primary"
            onClick={() => navigate("/nueva-cotizacion")}
          >
            <AddIcon />
          </Fab>
        </Box>
      </CardContent>
    </Card>
  );
}
