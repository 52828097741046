
import Tooltip from '@mui/material/Tooltip';
import { config } from '../../../../services/config';
import { getStatusLabel } from '../../../../utils/Commons'

export function StatusChip({ status }) {
  const { aproved, processing, canceled, finished } = config.orderStatus

  let emoji = "🟠" //pending by default

  if (status === canceled) emoji = "🔴"
  if (status === aproved) emoji = "🔵"
  if (status === processing) emoji = "🟢"
  if (status === finished) emoji = "🟣"

  return (
    <Tooltip title={getStatusLabel(status)}><div style={{ fontSize: '0.8em' }}>{emoji}</div></Tooltip>
  )
}