import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader } from "./DrawerHeader";
import { useNavigate } from "react-router-dom";
import { useMenues } from "./hooks/useMenues";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { accountsService } from "../../../../services";

export const DRAWER_WITH = 240;

export function AppDrawer({ open, handleDrawerClose, theme }) {
  const { menuRoot, menuVentas, menuQuotations } = useMenues();
  const navigate = useNavigate();

  function newListItem(item) {
    const { key, route, label, icon } = { ...item };
    return (
      <ListItem key={key} disablePadding>
        <ListItemButton
          onClick={() => {
            handleDrawerClose();
            navigate(route);
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <Drawer
      sx={{
        width: DRAWER_WITH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WITH,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <Divider />
      {menuVentas && menuVentas.length > 0 && (
        <>
          <List>{menuVentas.map((item) => newListItem(item))}</List>
          <Divider />
        </>
      )}

      <Divider />
      {menuQuotations && menuQuotations.length > 0 && (
        <>
          <List>{menuQuotations.map((item) => newListItem(item))}</List>
          <Divider />
        </>
      )}

      {menuRoot && menuRoot.length > 0 && (
        <>
          <List>{menuRoot.map((item) => newListItem(item))}</List>
          <Divider />
        </>
      )}

      <List>
        <ListItem key="logout" disablePadding>
          <ListItemButton
            onClick={() => accountsService.logout().then(navigate(0))}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
