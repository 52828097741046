import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";

import { useAcounts } from "../hooks/useAccounts";

const groupListItem = (group) => {
  const { route } = { ...group };

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="editar">
          <EditIcon />
        </IconButton>
      }
    >
      <ListItemText primary={route} />
    </ListItem>
  );
};

export function AppRoutes() {
  const { appRoutes } = useAcounts();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Rutas Rest</Typography>
        {appRoutes && <List>{appRoutes.map(groupListItem)}</List>}
      </CardContent>
    </Card>
  );
}
