import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { accountsService } from '../../../../services';
import { Typography } from '@mui/material';
import { useNotification } from '../../../notifications';

export function ProductMeta({ product, open, handleClose, addProduct }) {

  const defaults = {
    id: 0,
    IdProducto: 0,
    codigo: '',
    foto: '',
    name: '',
    vendor: '',
    description: '',
    qty: 1.0,
    price: 0,
    discount: 0,
  }
  const [hasMeta, setHasMeta] = useState(false)
  const { handleSubmit, register, setValue } = useForm({ defaultValues: defaults })
  const { displayNotification } = useNotification();

  function onSubmit(data) {
    if (!hasMeta) {
      console.log('es nuevo, guarda marca y descripcion', data)
      let { IdProducto, vendor, description } = data
      accountsService.postProductMeta({ id: IdProducto, vendor, description })
        .then(meta => {
          if (meta.success) displayNotification({ message: "Los datos adicionales del producto se guardaron con éxito.", type: "success" })
          else displayNotification({ message: "Ocurrió un error al guardar los datos adicionales del producto. Intentalo nuevamente.", type: "error" })
        })
    }

    addProduct(data)
  }

  useEffect(() => {

    if (!product) return
    //getMeta only if newly added product from Contabilium
    if (!product.id) {
      accountsService.getProductMeta(product.Id)
        .then(res => {
          if (res.success) {
            setHasMeta(true)
            setValue('vendor', res.data.vendor)
            setValue('description', res.data.description)
          } else {
            setHasMeta(false)
            setValue('vendor', '')
            setValue('description', '')
          }

          // this is a hack to show product data correctly in the cialog content.
          product.name = product.Nombre
          product.codigo = product.Codigo
          // -----------

          setValue('name', product.Nombre)
          setValue('price', 0)
          setValue('qty', 1)
          setValue('discount', 0)
          setValue('IdProducto', product.Id)
          setValue('codigo', product.Codigo)
          let foto = product.Foto === null ?
            'https://app.contabilium.com/files/usuarios/no-producto.png' :
            "https://app.contabilium.com/files/explorer/" + product.Foto
          setValue('foto', foto)
        })
    }
    // editing a quotation item
    else {
      setHasMeta(true)
      setValue('id', product.id)
      setValue('name', product.name)
      setValue('vendor', product.vendor)
      setValue('description', product.description)
      setValue('qty', product.qty)
      setValue('price', product.price)
      setValue('discount', product.discount)
      setValue('foto', product.foto)
    }

  }, [product, setValue])

  return (
    <>
      <form id="prodMetaForm" onSubmit={handleSubmit(onSubmit)}>
        {product && <input {...register("id", { value: product.id })} type="hidden" />}
        {product && <input {...register("IdProducto", { value: product.IdProducto })} type="hidden" />}
        {product && <input {...register("codigo", { value: product.codigo })} type="hidden" />}
        {product && <input {...register("foto", { value: product?.Foto })} type="hidden" />}
        <Dialog open={open} onClose={handleClose}>

          <DialogTitle>Información adicional para cotizar. </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{fontWeight: 'bold', marginTop: 5}}>
              {product?.Codigo} - {product?.Nombre}
            </DialogContentText>
            <Grid container spacing={2} sx={{ paddingTop: 4 }}>

              <Grid item xs={4}>
                <label htmlFor='qty'>
                  <Typography>Cantidad</Typography>
                </label>
                <TextField
                  type='number'
                  autoFocus
                  fullWidth
                  id="qty"
                  {...register('qty')}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <label htmlFor='price'>
                  <Typography>Precio</Typography>
                </label>
                <TextField
                  type='number'
                  fullWidth
                  id="price"
                  {...register('price')}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <label htmlFor='discount'>
                  <Typography>Bonif.</Typography>
                </label>
                <TextField
                  type='number'
                  fullWidth
                  id="discount"
                  {...register('discount')}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <label htmlFor='vendor'>
                  <Typography>Marca</Typography>
                </label>
                <TextField fullWidth id="vendor" {...register('vendor')} />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor='name'>
                  <Typography>Nombre</Typography>
                </label>
                <TextField fullWidth id="name" {...register('name')} />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor='description'>
                  <Typography>Descripción</Typography>
                </label>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  id="description"
                  {...register('description')}
                ></TextField>
              </Grid>

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" form="prodMetaForm" >
              Agregar
            </Button>
          </DialogActions>

        </Dialog>
      </form>
    </>
  )
}