import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { contabiliumService } from '../../../services';

export function SelectCondicionesDeVenta({selected, changeCondicionDeVenta, disabled = false}) {
    
  const [condiciones, setCondicionesDeVenta] = useState([]);

  useEffect(() => {
    contabiliumService.getCondicionesDeVenta()
      .then(
        p => {
          if (p.success) setCondicionesDeVenta(p.data)
        },
        err => {
          setCondicionesDeVenta([])
          console.log(err)
        }
      )
  }, [])

  return (

    <FormControl fullWidth required disabled={disabled}>
      <InputLabel id="condiciones-de-venta-select">Condicion De Venta</InputLabel>
      <Select
        labelId="condicion-de-venta--select"
        id="condicion-de-venta-select"
        value={condiciones.length > 0  && selected ? selected : ''}
        label="Condicion de Venta"
        onChange={e=> changeCondicionDeVenta(e.target.value)}
      >
        <MenuItem value="">
          <em>selecciona una condición</em>
        </MenuItem>
        {
          (condiciones !== []) && condiciones.map(
            p => {
              return (
                <MenuItem key={p.ID} value={p.ID}>{p.Nombre}</MenuItem>
              )
            }
          )

        }
      </Select>
    </FormControl>

  );
}