import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { IconTitle } from '../../../ui';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { currencyFormat } from '../../../../utils/Commons';

export function OrderItems(props) {
  const orderItems = props.orderItems
  const total = props.total
  const iva = props.iva
  const cantidad = props.cantidad
  const handleQtyChange = props.handleQtyChange
  const handleDiscountChange = props.handleDiscountChange
  const handleItemDelete = props.handleItemDelete

  function setCantidad(params) {
    handleQtyChange(params.row.id, params.value)
    return { ...params.row, Bonificacion: params.value }
  }

  function setBonificacion(params) {
    handleDiscountChange(params.row.id, params.value)
    return { ...params.row, Bonificacion: params.value }
  }

  const deleteButton = (params) => {
    return (
      <IconButton aria-label="eliminar" color="primary" onClick={event => { handleItemDelete(params.row.id) }}>
        <DeleteIcon />
      </IconButton>
    )
  }

  const columns = [
    {
      field: 'Cantidad', headerName: 'Cant.', flex: 0.6, editable: true, type: "number",
      valueSetter: setCantidad
    },
    { field: 'Nombre', headerName: 'Descripcion', flex: 2.6 },
    { field: 'PrecioFinal', headerName: 'Precio Final', flex: 0.7, align: "right", type: "number" },
    {
      field: 'Bonificacion', headerName: '% Bonif.', flex: 0.6, editable: true, align: "right", type: "number",
      valueSetter: setBonificacion
    },
    { field: 'Iva', headerName: '% IVA', flex: 0.5, align: "right", type: "number" },
    {
      field: 'SubTotal', headerName: 'Sub', flex: 0.7, align: "right", type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = currencyFormat(params.value);
        return `${valueFormatted}`;
      }
    },
    {
      field: 'actions', headerName: '', flex: 0.5, renderCell: deleteButton,
    }
  ];

  const noRows = () => {
    return (<Typography>Todavia no hay productos en el carrito</Typography>)
  }

  return (
    <Box sx={{ marginBottom: 3 }}>
      <IconTitle icon={ShoppingCart}>Productos</IconTitle>

      <Box>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={orderItems} columns={columns} hideFooter slots={{ noRowsOverlay: noRows }} />
        </div>
      </Box>

      <Container sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}><Typography variant='h6'>Cant. Items: {cantidad}</Typography></Grid>
          <Grid item xs={12} sm={4}><Typography variant='h6'>IVA: {(iva) && currencyFormat(iva)}</Typography></Grid>
          <Grid item xs={12} sm={4}><Typography variant='h6'>Total: {(total) && currencyFormat(total)} </Typography></Grid>
        </Grid>
      </Container>
    </Box>
  );
}
