import { useEffect, useMemo, useReducer, useState } from "react";
import {
  useAddCommentMutation,
  useAddGuideMutation,
  useCreateDeliveryMutation,
  useDeleteDeliveryMutation,
  useGetOrdersQuery,
  useSetOrderPayedMutation,
  useSetOrderStateMutation,
} from "../../../api/restApiSlice";
import { useNotification } from "../../../notifications";
import { config, ACTIONS } from "../../../../services/config";

function filterQuery(orders, query) {
  let uqry = query.toUpperCase().trim();
  if (uqry === "") return orders;
  return orders.filter(
    (o) => o.nombre.includes(uqry) || o.ordenContabiliumNro.includes(uqry)
  );
}

function matchFilter(order, filter) {
  const { pending, aproved, processing, canceled, finished } = {
    ...config.orderStatus,
  };
  if (order.estado === pending && filter.pending) return true;
  if (order.estado === aproved && filter.aproved) return true;
  if (order.estado === processing && filter.processing) return true;
  if (order.estado === canceled && filter.canceled) return true;
  if (order.estado === finished && filter.finished) return true;
}

const initialState = {
  dialog: "",
  selectedId: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW:
      const { dialog, selectedId } = { ...action.payload };
      return { ...state, dialog, selectedId };
    case ACTIONS.HIDE:
      return initialState;
    default:
      return state;
  }
};



export function useOrders() {
  const [query, setQuery] = useState("");
  const { displayNotification } = useNotification();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [statusFilter, setStatusFilter] = useState({
    pending: true,
    aproved: true,
    processing: true,
    finished: false,
    canceled: false,
  });

  const today = new Date();
  let lastMonth = new Date();
  lastMonth.setDate(today.getDate() - 30);
  const from = lastMonth.toISOString().split("T")[0];
  const to = today.toISOString().split("T")[0];

  const [fromTo, setFromTo] = useState({ from, to });

  const {
    data: orders,
    isLoading: isLoadingOrders,
    isSuccess: isLoadingOrdersSucces,
    isError: isLoadingOrdersError,
  } = useGetOrdersQuery(fromTo);

  const [
    addComment,
    {
      isLoading: isSavingComment,
      isSuccess: isCommentSuccess,
      isError: isCommentError,
    },
  ] = useAddCommentMutation();

  const [
    setOrderState,
    {
      isLoading: isStateSaving,
      isError: isStateError,
      isSuccess: isStateSuccess,
    },
  ] = useSetOrderStateMutation();

  const [
    setOrderPayed,
    {
      isLoading: isPayedSaving,
      isError: isPayedError,
      isSuccess: isPayedSuccess,
    },
  ] = useSetOrderPayedMutation();

  const [
    createDelivery,
    {
      isLoading: isDeliverySaving,
      isError: isDeliveryError,
      isSuccess: isDeliverySuccess,
    },
  ] = useCreateDeliveryMutation();

  const [
    deleteDelivery,
    {
      isLoading: isDeliveryDeleting,
      isError: isDeliveryDeleteError,
      isSuccess: isDeliveryDeleteSuccess,
    },
  ] = useDeleteDeliveryMutation();

  const [
    addGuide,
    {
      isLoading: isGuideSaving,
      isError: isGuideError,
      isSuccess: isGuideSuccess,
    },
  ] = useAddGuideMutation();

  useEffect(() => {
    isCommentSuccess &&
      displayNotification({
        message: "El Comentario se agregó exitósamente",
        type: "success",
      });
    isDeliveryDeleteSuccess &&
      displayNotification({
        message: "El Remito fue eliminado exitósamente",
        type: "success",
      });
    isDeliverySuccess &&
      displayNotification({
        message: "El Remito se generó exitósamente",
        type: "success",
      });
    isGuideSuccess &&
      displayNotification({
        message: "El Guía se agregó exitósamente",
        type: "success",
      });
    isPayedSuccess &&
      displayNotification({
        message: "El Pago se registró exitósamente",
        type: "success",
      });
    isStateSuccess &&
      displayNotification({
        message: "La orden fue actualizada exitósamente",
        type: "success",
      });
    dispatch({ type: ACTIONS.HIDE });
  }, [
    isStateSuccess,
    isCommentSuccess,
    isPayedSuccess,
    isDeliveryDeleteSuccess,
    isDeliverySuccess,
    isGuideSuccess,
  ]);

  useEffect(() => {
    isCommentError &&
      displayNotification({
        message: "No se pudo agregar el Comentario.",
        type: "error",
      });
    isDeliveryDeleteError &&
      displayNotification({
        message: "No se pudo Eliminar el Remito.",
        type: "error",
      });
    isDeliveryError &&
      displayNotification({
        message: "No se pudo generar el Remito.",
        type: "error",
      });
    isGuideError &&
      displayNotification({
        message: "No se pudo adjuntar la información de la Guía.",
        type: "error",
      });
    isLoadingOrdersError &&
      displayNotification({
        message: "No se pudo obtener la lista de órdenes desde el servidor.",
        type: "error",
      });
    isPayedError &&
      displayNotification({
        message: "No se pudo registrar el Pago de la orden.",
        type: "error",
      });
    isStateError &&
      displayNotification({
        message: "No se pudo Cancelar la orden.",
        type: "error",
      });
  }, [
    isCommentError,
    isStateError,
    isPayedError,
    isDeliveryError,
    isDeliveryDeleteError,
    isGuideError,
    isLoadingOrdersError,
  ]);


  function dispatchShow(dialog, selectedId) {
    dispatch({ type: ACTIONS.SHOW, payload: { dialog, selectedId } });
  }

  const createBilling = (_) =>
    window.open("https://app.contabilium.com/comprobantese.aspx", "_blank");
  const createReceipt = (_) =>
    window.open("https://app.contabilium.com/cobranzase.aspx", "_blank");

  const filteredOrders = useMemo(() => {
    if (!orders) return [];
    let queried = filterQuery(orders, query);
    let statusMatches = queried.filter((order) =>
      matchFilter(order, statusFilter)
    );
    return statusMatches;
  }, [query, statusFilter, orders]);

  return {
    addComment,
    addGuide,
    createBilling,
    createDelivery,
    createReceipt,
    deleteDelivery,
    dispatch,
    dispatchShow,
    filteredOrders,
    fromTo,
    isLoadingOrders,
    isLoadingOrdersSucces,
    setFromTo,
    setOrderPayed,
    setOrderState,
    setQuery,
    setStatusFilter,
    state,
    statusFilter,
    query,
  };
}
