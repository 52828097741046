import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";

import { useAcounts } from "../hooks/useAccounts";
const accountListItem = (account) => {
  const { email, fullname } = { ...account };

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="editar">
          <EditIcon />
        </IconButton>
      }
    >
      <ListItemText primary={fullname} secondary={email} />
    </ListItem>
  );
};
export function Accounts() {
  const { accounts } = useAcounts();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Cuentas de Usuario</Typography>
        {accounts && <List>{accounts.map(accountListItem)}</List>}
      </CardContent>
    </Card>
  );
}
