import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import { DeliveryHeader } from "./DeliveryHeader";

import { useDelivery } from "../hooks/useDelivery";

export function Delivery() {
  const { id } = useParams();
  const {
    entrega,
    nroRemito,
    fechaRemito,
    nombre,
    nroDoc,
    costoEnvio,
    condicionVenta,
    CondicionIva,
    Telefono,
    Email,
    items,
  } = useDelivery(id);
  return (
    <>
      <Box
        sx={{ margin: 5, border: 1, borderColor: "grey.500", borderRadius: 1 }}
      >
        <DeliveryHeader number={nroRemito} date={fechaRemito} />

        <Grid container sx={{ margin: 3, marginBottom: 2 }}>
          <Grid item xs={8}>
            <Typography>Nombre: {nombre}</Typography>
            <Typography>CUIT/CUIL/DNI: {nroDoc}</Typography>

            <Typography>Domicilio: {entrega.formated}</Typography>
            <Typography>
              {`Ubicacion: ${
                entrega.provincia  ? entrega.provincia.Nombre : ""
              }, ${entrega.localidad} (${entrega.cp})`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Condicion de Venta: {condicionVenta}</Typography>
            <Typography>Condicion de IVA: {CondicionIva}</Typography>
            <Typography>Telefono: {Telefono}</Typography>
            <Typography>Email: {Email}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: 5 }}>
        <Box>
          <Box
            sx={{
              minHeight: 500,
              border: 1,
              borderColor: "grey.500",
              borderRadius: 1,
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell>Codigo</TableCell>
                  <TableCell>Descripcion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.cantidad}</TableCell>
                    <TableCell>{row.SKU}</TableCell>
                    <TableCell>{row.descripcion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          <Grid container sx={{ mt:2 }}>
            <Grid item xs={6} sx={{borderRight: 'solid 1px', borderColor:"black", p:1}}>
              <Typography variant="h6">
                {entrega.fecha && "Fecha y hora entrega: " + entrega.fecha}
                {entrega.hora && " - " + entrega.hora}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p:1}}>
              <Typography variant="h6">
                {costoEnvio && "Costo del envio: $ " + costoEnvio}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
