import { config } from './config'
import { getContabiliumToken } from '../utils/Commons';

export const contabiliumService = {
  cancelOrder,
  getCiudades,
  getCliente,
  getComprobantePDF,
  getCondicionesDeVenta,
  getDepositos,
  getListasDePrecios,
  getOrder,
  getPaises,
  getProvincias,
  saveOrder,
  searchAfip,
  searchClientes,
  searchProducts,
  createFactura,
  createCliente
};

async function getBlob(url) {
  let token = getContabiliumToken();
  if (!token) return { success: false, message: 'No service Token ' }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.access_token
    }
  };
  try {
    let response = await fetch(url, requestOptions);
    let blob = await response.blob()
    let file = window.URL.createObjectURL(blob)
    window.open(file, '_blank');
    return blob
    /*
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
    */
  } catch (error) {
    return { success: false, data: error };
  }

}
async function getJson(url, object) {

  let token = getContabiliumToken();
  if (!token) return { success: false, message: 'No service Token ' }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.access_token
    },
    body: JSON.stringify(object)
  };
  try {
    let response = await fetch(url, requestOptions);
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
  } catch (error) {
    return { success: false, data: error };
  }
}
async function postJson(url, object) {

  let token = getContabiliumToken();
  if (!token) return { success: false, message: 'No service Token ' }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.access_token
    },
    body: JSON.stringify(object)
  };
  try {
    let response = await fetch(url, requestOptions);
    try {
      // try to parse response as json
      let json = await response.json()
      if (response.ok) return { success: true, data: json }
      else return { success: false, status: response.json }
    } catch (error) {
      return { success: false, status: response.status }
    }

  } catch (error) {
    return { success: false, data: error };
  }
}

async function getFetch(url) {
  // GET request con cabecera de Authorization
  // Devuelve un objeto JSON conteniendo un mensaje 'success' junto 
  // al resultado de la operacion del request

  let token = getContabiliumToken();
  if (!token) return { success: false, message: 'No service Token ' }
  let headers = { headers: { 'Authorization': 'Bearer ' + token.access_token } };
  try {
    let response = await fetch(url, headers);
    let json = await response.json()
    if (!response.ok) return { success: false, message: json }
    return { success: true, data: json }
  } catch (error) {
    return { success: false, error };
  }

}
async function searchClientes(pageSize, filtro) {
  let param1 = 'pageSize=' + pageSize
  let param2 = filtro ? '&filtro=' + filtro : ''
  let url = config.contabiliumUrl + '/clientes/search?' + param1 + param2;
  return await getFetch(url)
}

async function searchProducts(pageSize, filtro) {
  let param1 = 'pageSize=' + pageSize
  let param2 = filtro ? '&filtro=' + filtro : ''
  let url = config.contabiliumUrl + '/conceptos/search?' + param1 + param2;
  return await getFetch(url)
}

async function getPaises() {
  let url = config.contabiliumUrl + '/common/paises';
  return await getFetch(url)
}

async function getDepositos() {
  let url = config.contabiliumUrl + '/inventarios/getDepositos';
  return await getFetch(url)
}
async function getListasDePrecios() {
  let url = config.contabiliumUrl + '/listasDePrecio/search';
  return await getFetch(url)
}

//Obtener las provincias de un pais. Por defecto Argentina (10 en Contabilium)
//TODO: Cuando se reemplce en NewCustomer.js se puede eliminar. Ya esta en contabiliumApiSlice
async function getProvincias(pais = 10) {
  let param1 = '?idPais=' + pais
  let url = config.contabiliumUrl + '/common/provincias' + param1;
  return await getFetch(url)
}

async function getCiudades(provincia) {
  let param1 = '?idProvincia=' + provincia
  let url = config.contabiliumUrl + '/common/ciudades' + param1;
  return await getFetch(url)
} 

async function searchAfip(documento) {
  let param1 = '?documento=' + documento
  let url = config.contabiliumUrl + '/clientes/datosAfip' + param1;
  return await getFetch(url)
}

async function getCondicionesDeVenta() {
  let url = config.contabiliumUrl + '/usuarios/condicionesVenta'
  return await getFetch(url)
}

async function saveOrder(order) {
  let url = config.contabiliumUrl + '/ordenesVenta'
  //desde marzo 2024 se quita la observacion de la orden de venta que viaja a contabilium
  var order2 = {...order}
  order2.observaciones = ""
  return await postJson(url, order2)
}

async function getCliente(id) {
  let url = config.contabiliumUrl + '/clientes'
  let param1 = '?id=' + id
  return await getFetch(url + param1)
}

async function getOrder(id) {
  let url = config.contabiliumUrl + '/ordenesVenta'
  let param1 = '?id=' + id
  return await getFetch(url + param1)
}

async function cancelOrder(id) {
  let url = config.contabiliumUrl + '/ordenesVenta/Cancel'
  let param1 = '?id=' + id
  return await postJson(url + param1)
}

async function createFactura(orderId) {
  let url = config.contabiliumUrl + '/ordenesventa/emitirFE?nro=' + orderId
  return await getJson(url)
}

async function createCliente(body) {
  let url = config.contabiliumUrl + '/clientes'
  return postJson(url, body)
}

async function getComprobantePDF(idComprobante) {
  let url = config.contabiliumUrl + '/comprobantes/obtenerPdf/?id=' + idComprobante
  return getBlob(url)
}