import { authHeader } from './auth-header';
import { config } from './config';
import { SHA3 } from 'sha3'
import { getUser, setContabiliumToken } from '../utils/Commons'

export const accountsService = {
  getServerStatus,
  checkToken,
  getAccountInfo,
  login,
  logout,
  updateAccountInfo,
  register,
  getVendorToken,

  getOrdersExport,
  getProductsOrdersExport,
  saveOrder,
  getOrder,
  updateOrder,
  
  getProductMeta, 
  postProductMeta,

  getQuotations,
  getQuotation,
  saveQuotation,

};

async function putJson(url, object) {

  let user = getUser();
  if (!user) return { success: false, message: 'User is not Signed in' }

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + user.token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(object)
  };
  try {
    let response = await fetch(url, requestOptions);
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
  } catch (error) {
    return { success: false, data: error };
  }
}

async function postUnauthorizedJson(url, object) {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(object)
  };
  try {
    let response = await fetch(url, requestOptions);
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
  } catch (error) {
    return { success: false, data: error };
  }
}

async function postJson(url, object) {

  let user = getUser();
  if (!user) return { success: false, message: 'User is not Signed in' }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.token
    },
    body: JSON.stringify(object)
  };
  try {
    let response = await fetch(url, requestOptions);
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
  } catch (error) {
    return { success: false, data: error };
  }
}

async function getFetch(url) {
  // GET request con cabecera de Authorization
  // Devuelve un objeto JSON conteniendo un mensaje 'success' junto 
  // al resultado de la operacion del request

  let user = getUser();
  if (!user) return { success: false, message: 'User is not Signed in' }
  let requestOptions = { headers: { 'Authorization': 'Bearer ' + user.token } };
  try {
    let response = await fetch(url, requestOptions);
    let json = await response.json()
    if (response.ok) return { success: true, data: json }
    else return { success: false, data: json }
  } catch (error) {
    return { success: false, data: error };
  }
}

const authorizedGET = {
  method: 'GET',
  headers: authHeader()
}

function getVendorToken() {
  return fetch(`${config.apiUrl}/vendor-token`, authorizedGET)
    .then(handleResponse)
    .then(
      results => {
        setContabiliumToken(results);
        return results;
      }
    );
}

function getServerStatus() {

  return fetch(`${config.apiUrl}/status`, authorizedGET)
    .then(handleResponse)
    .then(
      results => {
        return results;
      }
    );
}

function updateAccountInfo(accountinfo) {

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(accountinfo)
  };

  return fetch(`${config.apiUrl}/account-info`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

function getAccountInfo() {

  return fetch(`${config.apiUrl}/account-info`, authorizedGET)
    .then(handleResponse)
    .then(
      results => {
        return results;
      }
    );
}

async function checkToken() {
  return await getFetch(`${config.apiUrl}/check-token`)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        //TODO: Check this out => location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

async function login(email, password) {

  const hash = new SHA3(512)
  hash.update(password)
  const password_hash = hash.digest('hex')
  let url = `${config.apiUrl}/login`
  return await postUnauthorizedJson(url, { email, password: password_hash })
}

function logout() {
  // remove user from session storage
  sessionStorage.clear()

  return Promise.resolve()
}

function register(user) {

  const hash = new SHA3(512)
  hash.update(user.password)
  const body = {
    fullname: user.fullname,
    email: user.email,
    phone: user.phone,
    password: hash.digest('hex')
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  return fetch(`${config.apiUrl}/register`, requestOptions)
    .then(response => { return handleResponse(response) })
}

async function getOrdersExport(from, to) {
  let url = `${config.salesUrl}/orders-export?from=${from}&to=${to}`
  return fetch(url)
}

async function getProductsOrdersExport(from, to, statusFilter) {
  let url = `${config.salesUrl}/products-orders-export?from=${from}&to=${to}&${statusFilter}`
  return fetch(url)
}

async function saveOrder(order) {
  let url = `${config.salesUrl}/orders`
  return postJson(url, order)
}

async function updateOrder(id, order) {
  let url = `${config.salesUrl}/order/${id}`
  return putJson(url, order)
}

async function getOrder(id) {
  let url = `${config.salesUrl}/order/${id}`
  return getFetch(url)
}

async function getProductMeta(id) {
  let url = `${config.salesUrl}/product-meta/${id}`
   return getFetch(url)
}

async function postProductMeta(data) {
  let url = `${config.salesUrl}/product-meta`
   return postJson(url, data)
}

async function getQuotations() {
  let url = `${config.salesUrl}/quotations`
  return await getFetch(url)
}
async function getQuotation(id) {
  let url = `${config.salesUrl}/quotations/${id}`
  return await getFetch(url)
}

async function saveQuotation(quotation) {
  let url = `${config.salesUrl}/quotations`
  debugger
  return await postJson(url, quotation)
}