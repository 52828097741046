import { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { contabiliumService } from "../../../../services/contabilium.service";

export function ContabiliumProductsSearch({ onSelect }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    setLoading(true);
    contabiliumService.searchProducts(20, inputValue).then(
      (res) => {
        if (active && res.success) {
          setLoading(false);
          setOptions(res.data.Items);
        }
      },
      (err) => console.log
    );
    return () => {
      active = false;
    };
  }, [inputValue]);

  useEffect(() => {
    if (!value) return;
    onSelect(value);
  }, [value]);

  return (
    <Autocomplete
      isOptionEqualToValue={(opt, value) =>
        opt.Nombre === value.Nombre || opt.Codigo === value.Codigo
      }
      getOptionLabel={(opt) => `${opt.Nombre} - ${opt.Codigo}`}
      loading={loading}
      options={options}
      value={value}
      size="small"
      noOptionsText="No se econtraron productos"
      onChange={(_, newValue) => setValue(newValue)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Productos"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
