import { useReducer, useState } from "react"
import { accountsService } from "../../../../services"

const emptyQuotation = {
  idCliente: 123,
  name: "",
  building: "",
  attention: "",
  address: "",
  contactPerson: "",
  phone: "",
  paymentMethod: "Precio por pago al contado.",
  tender: "7 días.",
  vendorWarranty: "24 meses.",
  shipping: "Sin cargo a todo el país.",
  localCurrency: 0,
  items: [],
  subtotal: 0,
  iva: 0,
  total: 0,
}



export function useQuotation() {

  const [selectedProduct, setSelectedProduct] = useState()
  const [quotation, setQuotation] = useState(emptyQuotation)

  function selectProduct(product) {
    setSelectedProduct(product)
  }

  function recalculate(items) {
    var subtotal = 0;
    var iva = 0;
    var total = 0;

    for (var x = 0; x < items.length; x++) {
      let i = items[x]
      subtotal = i.price * i.qty
      iva += subtotal * (21 / 100)
      total += subtotal + iva
    }
    return { subtotal, iva, total }
  }

  function addProduct(product) {

    let state = [...quotation.items]
    if (!product?.id) state.push({ ...product, id: state.length + 1 })
    else {
      state.forEach((p) => {
        if (p.id === product.id) {
          let { name, description, price, qty, discount, vendor } = { ...product }
          p.name = name
          p.description = description
          p.price = price
          p.qty = qty
          p.discount = discount
          p.vendor = vendor
        }
      })
    }
    let { subtotal, iva, total } = recalculate(state)
    let q = { ...quotation, items: state, subtotal, iva, total }
    setQuotation(q)
  }


  function getQuotation(id) {
    accountsService.getQuotation(id)
      .then(
        res => { if (res.success) setQuotation(res.data) },
        err => { console.log(err) }
      )
  }

  function saveQuotation() {
    accountsService.saveQuotation(quotation)
      .then(
        res => {
          console.log('res', res)
          //if (res.success) //cambiar el id?
        },
        err => console.log('err', err)
      )
  }

  const setName = (name) => setQuotation({ ...quotation, name })
  const setBuilding = (building) => setQuotation({ ...quotation, building })
  const setAttention = (attention) => setQuotation({ ...quotation, attention })
  const setContactPerson = (contactPerson) => setQuotation({ ...quotation, contactPerson })
  const setPhone = (phone) => setQuotation({ ...quotation, phone })
  const setAddress = (address) => setQuotation({ ...quotation, address })


  return {
    quotation, getQuotation, saveQuotation,
    selectedProduct, selectProduct, addProduct,
    setName, setBuilding, setAttention, 
    setContactPerson, setPhone, setAddress
  }
}