import { Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { config } from "../../../services/config";

export function ImagesModal({ open, imagesArray = [], handleClose }) {
  const [openLightBox, setOpenLightBox] = useState(false)
  const [selectedImage, setSelectedImage] = useState()

  const showLightBox = (e, image) => {
    e.preventDefault()
    setOpenLightBox(true)
    setSelectedImage(image)
  }

  const closeLightBox = (e) => {
    e.preventDefault()
    setOpenLightBox(false)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid #0f0f0f',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    maxHeight: '95%',
    overflowY: "scroll"
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ImageList cols={2} rowHeight="auto">
            {imagesArray.map((item) => (
              <ImageListItem key={item} sx={{ width: 'fit-content', height: 'fit-content' }}>
                <img
                  src={`${config.restServerUrl}/uploads/${item}`}
                  srcSet={`${config.restServerUrl}/uploads/${item}`}
                  alt={item}
                  loading="lazy"
                  onClick={e => showLightBox(e, item)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Modal>

      <Dialog
        fullWidth={true}
        open={openLightBox}
        sx={{ border: '2px solid #000', }}
        onClose={closeLightBox}
      >
          <img
            src={`${config.restServerUrl}/uploads/${selectedImage}`}
            alt={selectedImage}
            loading="lazy"
            onClick={closeLightBox}
          />
      </Dialog>
    </>
  )
}