import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Info from '@mui/icons-material/Info';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Schedule from '@mui/icons-material/Schedule'
import { IconTitle } from '../../../ui';
import { SelectProvincias } from '../../../ui';

export function ShippingForm({ shippingInfo, updateShipping, mustRequired, readOnly }) {

  return (

    <Box component="form" noValidate autoComplete='off'>
      <IconTitle icon={LocalShipping}>Direccion de Entrega</IconTitle>
      <Typography sx={{ margin: 1, marginBottom: 2 }}>
        Ingresa la Dirección de Entrega solo si los productos se entregarán
        en un domicilio distinto al domicilio fiscal.
      </Typography>
      <Box sx={{ margin: 1, marginBottom: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              required={mustRequired()}
              fullWidth
              autoComplete='off'
              onChange={e => updateShipping({ ...shippingInfo, direccionEntrega: e.target.value })}
              label="Direccion (calle y nro)"
              value={shippingInfo.direccionEntrega}
              disabled={readOnly}
            />

          </Grid>
          <Grid item xs={3}>
            <TextField
              onChange={e => updateShipping({ ...shippingInfo, pisoDeptoEntrega: e.target.value })}
              label="Piso y Dto."
              autoComplete='off'
              value={shippingInfo.pisoDeptoEntrega}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectProvincias
              required={mustRequired()}
              provincia={shippingInfo.provinciaEntrega ? shippingInfo.provinciaEntrega : ''}
              changeProvincia={value => updateShipping({ ...shippingInfo, provinciaEntrega: value })} 
              disabled={readOnly}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={mustRequired()}
              fullWidth
              onChange={e => updateShipping({ ...shippingInfo, localidadEntrega: e.target.value })}
              label="Localidad"
              autoComplete='off'
              value={shippingInfo.localidadEntrega}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              required={mustRequired()}
              fullWidth
              onChange={e => updateShipping({ ...shippingInfo, cpEntrega: e.target.value })}
              label="Cod. Postal"
              autoComplete='off'
              value={shippingInfo.cpEntrega}
              disabled={readOnly}
            />
          </Grid>

        </Grid>
      </Box>

      <IconTitle icon={Schedule}>Entrega Diferida</IconTitle>
      <Box sx={{ margin: 1, marginBottom: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Fecha de Entrega"
              onChange={e => updateShipping({ ...shippingInfo, fechaEntrega: e.target.value })}
              value={shippingInfo.fechaEntrega}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Dias de gracia"
              onChange={e => updateShipping({ ...shippingInfo, diasDeGracia: e.target.value })}
              value={shippingInfo.diasDeGracia}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Box>

      <IconTitle icon={Info}>Información adicional</IconTitle>
      <Box sx={{ margin: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="Quién recibe?"
              onChange={e => updateShipping({ ...shippingInfo, quienRecibe: e.target.value })}
              value={shippingInfo.quienRecibe}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Telefono" autoComplete='off'
              onChange={e => updateShipping({ ...shippingInfo, telefonoRecibe: e.target.value })}
              value={shippingInfo.telefonoRecibe}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}