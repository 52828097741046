import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useCommons } from "../hooks/useCommons";

export function SelectProvincias({ changeProvincia, disabled, provincia }) {
  const { provincias } = useCommons();

  const handleChange = (event) => {
    let id = event.target.value;
    changeProvincia(id);
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="provincias-select">Provincia</InputLabel>
      <Select
        labelId="provincias-select"
        id="provincias-select"
        value={provincias && provincia ? provincia : ""}
        label="Provincia"
        onChange={handleChange}
        name="provincia"
      >
        <MenuItem value="">
          <em>selecciona una provincia</em>
        </MenuItem>
        {provincias &&
          provincias.map((p) => {
            return (
              <MenuItem key={p.ID} value={p.ID}>
                {p.Nombre}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
