import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export function Conditions({quotation}) {

  return (
    <List>
      <ListItem disablePadding>
        <ListItemText>Forma de pago: {quotation.paymentMethod}</ListItemText>
      </ListItem>
      <ListItem disablePadding>
        <ListItemText>Validez de la oferta: {quotation.tender}</ListItemText>
      </ListItem>
      <ListItem disablePadding>
        <ListItemText>Garantía del fabricante: {quotation.vendorWarranty}</ListItemText>
      </ListItem>
      <ListItem disablePadding>
        <ListItemText>Envío: {quotation.shipping}</ListItemText>
      </ListItem>
      <ListItem disablePadding>
        <ListItemText>Los precios de este presupuesto estan expresados en Dolares Estadounidences (USD)</ListItemText>
      </ListItem>
    </List>
    
      
  )
}