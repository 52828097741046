import * as React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import EditIcon from '@mui/icons-material/Edit';
import AttachIcon from '@mui/icons-material/Attachment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { config } from '../../../../services/config';
import { StyledMenu } from '../../../ui/components/StyledMenu';

export const ACTIONS = {
  CANCEL: 'cancel',
  APROVE: 'aprove',
  PROCESS: 'process',
  EDIT: 'edit',
  ATTACH: 'attach',
  RESET: 'reset', 
  PREVIEW: 'preview'
}

export function QuotationActions({ quotation, dispatch }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { aproved, pending, processing, canceled, finished } = { ...config.orderStatus }

  function cantCancel() { return quotation.status === pending || quotation.status === processing }
  function cantProcess() { return quotation.status !== aproved }
  function cantEdit() { return quotation.status === canceled || quotation.status === finished }

  function handleClose() { setAnchorEl(null); }

  function handleClick(type) {
    handleClose()
    dispatch({ type, payload: { id: quotation.id } })
  }
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/**
         * APROBAR
         */}
        <MenuItem
          key="aprobar"
          onClick={() => handleClick(ACTIONS.APROVE)}
          disabled={(quotation.status !== 0)}
        >
          <ListItemIcon><SpellcheckIcon /></ListItemIcon>
          <ListItemText>Aprobar</ListItemText>
        </MenuItem>

        {/**
         * CANCELAR
         */}
        <MenuItem
          key="cancelar"
          onClick={() => handleClick(ACTIONS.CANCEL)}
          disabled={cantCancel()}
        >
          <ListItemIcon><CancelIcon /></ListItemIcon>
          <ListItemText>Cancelar</ListItemText>
        </MenuItem>

        {/**
         * PROCESAR
         */}
        <MenuItem
          key="procesar"
          onClick={() => handleClick(ACTIONS.PROCESS)}
          disabled={cantProcess()}
        >
          <ListItemIcon><AddCircleIcon /></ListItemIcon>
          <ListItemText>Procesar</ListItemText>
        </MenuItem>


        <Divider />

        {/**
         * EDITAR
         */}
        <MenuItem
          key="editar"
          onClick={() => handleClick(ACTIONS.EDIT)}
          disabled={cantEdit()}
        >
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>

        {/**
         * ADJUNTAR
         */}
        <MenuItem
          key="adjuntar"
          onClick={() => handleClick(ACTIONS.ATTACH)}
          disabled={cantEdit()}
        >
          <ListItemIcon><AttachIcon /></ListItemIcon>
          <ListItemText>Adjuntar Archivos</ListItemText>
        </MenuItem>

        {/**
         * VISTA PREVIA
         */}
        <MenuItem
          key="vista-previa"
          onClick={() => handleClick(ACTIONS.PREVIEW)}
          disabled={cantEdit()}
        >
          <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
          <ListItemText>Vista Previa</ListItemText>
        </MenuItem>

      </StyledMenu>
    </>
  )
}