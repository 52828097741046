const { useDispatch } = require("react-redux");
const { NotificationActions } = require("../state/notificationSlice");

export const useNotification = () => {
  const dispatch = useDispatch();

  const displayNotification = (notification) => {
    dispatch(NotificationActions.addNotification(notification));
  };

  const clearNotification = () => {
    dispatch(NotificationActions.clearNotification());
  };

  return { displayNotification, clearNotification };
};
