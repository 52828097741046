import { useState } from "react"

const emptyQuotation = {
  idCliente: 123,
  name: "Lucas",
  building: "San Martín 2",
  paymentMethod: "Precio por pago al contado.",
  tender: "7 días.",
  vendorWarranty: "24 meses.",
  shipping: "Sin cargo a todo el país.",
  items: []
}
export function useProductMeta() {

  const [openMeta, setOpenMeta] = useState(false);

  function showMeta() { setOpenMeta(true) }
  function hideMeta() { setOpenMeta(false) }

  return { openMeta, showMeta, hideMeta }
}