import {
  useGetAccountsQuery,
  useGetAppRoutesQuery,
} from "../../api/accountsApiSlice";
 //TODO: Descubrí que esta organizacion hace que se ejecuten ambas query en cualquiera de las instancias de usAccounts.
 //Conviene sepemara un useGet/etc por Dominio (UserGroups, AppRoutes, etc)
 
export function useAcounts() {
  const {
    data: appRoutes,
    isError: routesError,
    isLoading: routesLoading,
    isSuccess: routesSuccess,
  } = useGetAppRoutesQuery();


  const {
    data: accounts,  
  } = useGetAccountsQuery();

  return { accounts, appRoutes };
}
