
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup';
import { Typography } from '@mui/material';

export function StatusFilter({ statusFilter, onStatusFilterChange }) {

  const statusChange = (e) => {
    let state = {
      ...statusFilter,
      [e.target.name]: !statusFilter[e.target.name]
    }
    onStatusFilterChange(state)
  }

  return (
    <>
      <Typography variant='subtitle1'>Estados</Typography>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControlLabel control={<Checkbox id="check-pending" name="pending" checked={statusFilter.pending} onChange={statusChange} size="small" color="warning" />} label="Pendiente" />
        <FormControlLabel control={<Checkbox id="check-aproved" name="aproved" checked={statusFilter.aproved} onChange={statusChange} size="small" color="info" />} label="Aprobada" />
        <FormControlLabel control={<Checkbox id="check-processing" name="processing" checked={statusFilter.processing} onChange={statusChange} size="small" color="success" />} label="En Curso" />
        <FormControlLabel control={<Checkbox id="check-finished" name="finished" checked={statusFilter.finished} onChange={statusChange} size="small" color="secondary" />} label="Finalizada" />
        <FormControlLabel control={<Checkbox id="check-canceled" name="canceled" checked={statusFilter.canceled} onChange={statusChange} size="small" color="error" />} label="Cancelada" />
      </FormGroup>

    </>
  )
}