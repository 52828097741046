import * as React from "react";
import Divider from "@mui/material/Divider";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import EditNoteIcon from "@mui/icons-material/Edit";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import { config } from "../../../../services/config";
import { StyledMenu } from "../../../ui/components/StyledMenu";

export function AdminOrderActions({
  order,
  aproveOrder,
  addComment,
  billOrder,
  receiptOrder,
  showDeliver,
  showDeliveryDelete,
  showCancel,
  showGuide,
  showFinish,
  createCOT, 
  markPayed,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { pending, processing, canceled, finished } = { ...config.orderStatus };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
    showCancel(order.ordenContabilium);
  };

  const handleDelivery = () => {
    handleClose();
    showDeliver(order.id);
  };

  const handleCOT = () => {
    handleClose();
    createCOT(order.id);
  };

  const handleDeliveryDelete = () => {
    handleClose();
    showDeliveryDelete(order.id);
  };

  const disableDeliver = () => {
    return (
      order.deliveries.length > 0 ||
      order.estado === canceled ||
      order.estado === finished ||
      order.estado === pending
    );
  };

  const disableDeliveryDelete = () => {
    return order.deliveries.length === 0 || order.estado === finished;
  };

  const handleApprove = () => {
    handleClose();
    aproveOrder(order.ordenContabilium);
  };

  const handleBilling = () => {
    handleClose();
    //if (order.condicionVenta === config.condicionVtaBiocook) copyOrderToBiocook()
    billOrder();
  };

  const disableBilling = () => {
    return (
      order.comprobanteContabilium !== null ||
      order.estado === canceled ||
      order.estado === finished ||
      order.estado === pending
    );
  };

  const handleReceipt = () => {
    handleClose();
    receiptOrder();
  };

  const canCancel = () => {
    return order.estado !== finished && order.comprobanteContabilium == null;
  };

  const handleSetGuideNumber = () => {
    handleClose();
    showGuide(order.id);
  };
  const canSetGuide = () => {
    return (
      order.deliveries.length === 0 ||
      order.estado === canceled ||
      order.estado === finished ||
      order.estado === pending
    );
  };

  const handleFinish = () => {
    handleClose();
    showFinish(order.ordenContabilium);
  };

  const canFinish = () => {
    return order.estado === processing;
  };

  function handlePayed() {
    handleClose();
    markPayed(order.id);
  }
  function handleNewComment() {
    handleClose();
    addComment(order.id);
  }

  function disablePayed() {
    return order.pagado !== 0;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/**
         * APROBAR
         */}
        <MenuItem
          key="aprobar"
          onClick={handleApprove}
          disabled={order.estado !== 0}
        >
          <ListItemIcon>
            <SpellcheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Aprobar</ListItemText>
        </MenuItem>

        {/**
         * CANCELAR
         */}
        <MenuItem key="cancelar" onClick={handleCancel} disabled={!canCancel()}>
          <ListItemIcon>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cancelar</ListItemText>
        </MenuItem>

        {/**
         * FINALIZAR
         */}
        <MenuItem
          key="finalizar"
          onClick={handleFinish}
          disabled={!canFinish()}
        >
          <ListItemIcon>
            <DoneAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Finalizar</ListItemText>
        </MenuItem>

        {/**
         * INICAR CONVERSACION
         */}
        <MenuItem
          key="comentarios"
          onClick={handleNewComment}
          //disabled={!canFinish()}
        >
          <ListItemIcon>
            <EditNoteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Comentar...</ListItemText>
        </MenuItem>

        <Divider />

        {/**
         * RECIBO
         */}
        <MenuItem key="recibo" onClick={handleReceipt} disabled={!canCancel}>
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Recibo</ListItemText>
        </MenuItem>

        {/**
         * FACTURAR
         */}
        <MenuItem
          key="facturar"
          onClick={handleBilling}
          disabled={disableBilling()}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {"Factura" +
              (order.condicionVenta === config.condicionVtaBiocook
                ? " (en Biocook)"
                : "")}
          </ListItemText>
        </MenuItem>

        {/**
         * PAGADO
         */}
        <MenuItem key="pagado" onClick={handlePayed} disabled={disablePayed()}>
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Pagado</ListItemText>
        </MenuItem>

        {/**
         * REMITO
         */}
        <MenuItem
          key="remitir"
          onClick={handleDelivery}
          disabled={disableDeliver()}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remito</ListItemText>
        </MenuItem>

        {/**
         * REMITO
         */}
        <MenuItem
          key="COT"
          onClick={handleCOT}
          disabled={disableDeliveryDelete()} //Coincide con la condicion de eliminacion
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Obtener COT</ListItemText>
        </MenuItem>

        <MenuItem
          key="eliminar-remito"
          onClick={handleDeliveryDelete}
          disabled={disableDeliveryDelete()}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Eliminar Remito</ListItemText>
        </MenuItem>

        {/**
         * GUIA
         */}
        <MenuItem
          key="guia"
          onClick={handleSetGuideNumber}
          disabled={canSetGuide()}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText># Guia</ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  );
}
