import { createTheme} from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#990033',
    },
    secondary: {
      main: '#e15f53',
    },
    background: {
      default: "#f4f4f4"
    },
    text: {
      primary: '#040f18',
    },
  },
  typography: {
    fontFamily: ["IBM Plex Sans", "Noto Sans JP, Roboto"].join(",")
  },
});